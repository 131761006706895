export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/auth/home";
export const ACCOUNT = "/auth/account";
export const ADMIN = "/auth/admin";
export const PASSWORD_FORGET = "/auth/pw-forget";

export const INVENTORY = "/auth/inventory";
export const DASHBOARD = "/auth/dashboard";
export const SETTINGS = "/auth/settings";
export const REQUESTS = "/auth/requests";
export const INBOX = "/auth/inbox";
export const DEVELOPERS = "/auth/developers";
export const COMPOUNDS = "/auth/compounds";
export const PHASES = "/auth/phases";
