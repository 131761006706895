import {
    FETCH_COMPOUND_PHASES,
    IS_LOADING,
    SUCCESS_FETCH_COMPOUND_PHASES,
    FAIL_FETCH_COMPOUND_PHASES,

    ADD_NEW_PHASE,
    SUCCESS_ADD_NEW_PHASE,
    FAIL_ADD_NEW_PHASE,
    RESET_ADD_PHASE_STATE,

    DELETE_PHASE,
    SUCCESS_DELETE_PHASE,
    FAIL_DELETE_PHASE,
    RESET_DELETE_STATE,

} from "../Constants/phases";

import axios from "axios";

const fetchCompoundPhases = (phases) => ({
    type: FETCH_COMPOUND_PHASES,
    phases
})

const loading = (isLoading) => ({
    type: IS_LOADING,
    isLoading
})

const successFetchCompoundPhases = (success) => ({
    type: SUCCESS_FETCH_COMPOUND_PHASES,
    success
})

const failFetchCompoundPhases = (error) => ({
    type: FAIL_FETCH_COMPOUND_PHASES,
    error
})

const addNewPhase = (phase) => ({
    type: ADD_NEW_PHASE,
    phase
})
const successAddNewPhase = (success) => ({
    type: SUCCESS_ADD_NEW_PHASE,
    success
})

const failedAddNewPhase = (error) => ({
    type: FAIL_ADD_NEW_PHASE,
    error
})

const resetAddState = (added) => ({
    type: RESET_ADD_PHASE_STATE,
    added
})

const deletePhase = (id) => ({
    type: DELETE_PHASE,
    id,
})

const successDeletePhase = (success) => ({
    type: SUCCESS_DELETE_PHASE,
    success,
})

const failedDeletePhase = (error) => ({
    type: FAIL_DELETE_PHASE,
    error,
})
const resetDeleteState = (deleted) => ({
    type: RESET_DELETE_STATE,
    deleted
})

export const handleFetchCompoundPhases = (compoundId) => async (dispatch) => {
    dispatch(loading(false))
    axios.get(`/phases/${compoundId}`)
        .then(res => {

            //allPhases
            const phases = res.data.allPhases.map(phase => ({
                id: phase.id,
                ...phase.data
            }))
            dispatch(fetchCompoundPhases(phases))
            dispatch(loading(true))
            dispatch(successFetchCompoundPhases({
                type: true,
                message: "Success to get phases data of this compound"
            }))
        })
        .catch(error => {
            dispatch(loading(true))

            dispatch(failFetchCompoundPhases({
                type: false,
                message: "Failed to get phases data of this compound"
            }))
        })
}

export const handleAddNewPhase = (phase) => async (dispatch) => {
    axios.post('/phases', {
        ...phase
    }).then(res => {
        dispatch(addNewPhase({
            id: res.data.id,
            ...res.data.data
        }))
        dispatch(successAddNewPhase({
            type: true,
            message: "Successful to add new phase"
        }))
        dispatch(resetAddState(null))
    }).catch(error => {
        dispatch(failedAddNewPhase({
            type: false,
            message: "Failed to add new phase"
        }))
        dispatch(resetAddState(null))
    })
}

export const handleDeletePhase = (phase) => async (dispatch) => {
    dispatch(deletePhase(phase.id))

    axios.delete(`/phases/historical/${phase.id}`)
        .then(res => {
            dispatch(successDeletePhase({
                type: true,
                message: "Successful deleted phase: " + phase.phase_name
            }))
            dispatch(resetDeleteState(null))
        })
        .catch(error => {

            dispatch(failedDeletePhase({
                type: false,
                message: "Failed to delete phase: " + phase.name
            }))
            dispatch(addNewPhase(phase))
            dispatch(resetDeleteState(null))
        })
}