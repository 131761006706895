import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import InboxIcon from "@material-ui/icons/MoveToInbox";
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import styles from './index.module.scss';



class SidebarTab extends Component {
  render() {
    const { to, pageName, index } = this.props;
    return (
      <Link to={to} className={`${styles.setFontSize}`}>
        <ListItem button key={pageName}>
          <ListItemIcon>
            <>
              {index === 0 ?  <HomeOutlinedIcon /> : <></>}
              {index === 1 ?  <DashboardOutlinedIcon /> : <></>}
              {index === 2 ?  <InboxIcon /> : <></>}
              {index === 3 ?  <CodeOutlinedIcon /> : <></>}
              {index === 4 ?  <ReceiptOutlinedIcon /> : <></>}
              {index === 5 ?  <SettingsOutlinedIcon /> : <></>}
            </>
          </ListItemIcon>

          <ListItemText primary={pageName} />
        </ListItem>
      </Link>
    );
  }
}

export default SidebarTab;
