import React, { Component } from "react";
import MaterialTable, { MTableToolbar } from 'material-table';

import Toolbar from '../Toolbar';


const dynamicColumns =[
    { title: 'YYYYYYYYYYuYYYYYYYYY', field: 'name' },
    { title: 'Surname', field: 'surname' },
    { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
    {title: 'Birth Place',field: 'birthCity', lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
    },
];

const dynamicData = [
  { name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 },
  { name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, birthCity: 34 },
];

class InventoryImageView extends Component {
  render() {
    return (
      <MaterialTable
      // other props
      components={{
        Toolbar: props => (
            <>
            {console.log(props)}
              <MTableToolbar {...props}/>
              <Toolbar changeViewType={this.props.changeViewType} />
            </>
          ),
        }}
        columns={dynamicColumns}
        data={dynamicData}
        options={{
          selection: true
        }}
      />
    );
  }
}

export default InventoryImageView;
