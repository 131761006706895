import React, { Component } from "react";

import { withRouter, Link } from "react-router-dom";
import { compose } from "redux";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import axios from "axios";

import Swal from "sweetalert2";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

class SignInForm extends Component {
  state = { ...INITIAL_STATE };
  showMap = async (position, email) => {
    const logs = await axios.post(`/logs`, {
      log: {
        email,
        event: "login",
        "location": {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
        "event_info": {
        }
      }
    })
    console.log("logs", logs);
    
  }

  onSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = this.state;

    try {
      const res = await axios.post(`/users/login`, { email });
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => this.showMap(position, email));
      }

      if (res.data.controlPanel === true) {
        this.props.firebase
          .doSignInWithEmailAndPassword(email, password)
          .then((authUser) => {
            localStorage.removeItem("loggedUserId");
            localStorage.setItem("loggedUserId", authUser.user.uid);
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(ROUTES.HOME);
          })
          .catch((error) => {
            this.setState({ error });
          });
      }
    } catch (err) {
      if (err.response.data.controlPanel === false) {
        Swal.fire({
          title: "Error!",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "close",
        });
      }
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === "" || email === "";
    const { changeView } = this.props;
    return (
      <section className={`col-12`}>
        <form onSubmit={this.onSubmit} className={`py-5`}>
          <div className={`form-group`}>
            <input
              name="email"
              className={`form-control`}
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
          </div>
          <div className={`form-group`}>
            <input
              className={`form-control`}
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
          </div>

          <button
            disabled={isInvalid}
            type="submit"
            className={`btn btn-primary btn-block`}
          >
            Sign In
          </button>
          <Link to="#" className={`text-center`} onClick={changeView}>
            Don't have an account Sign up
          </Link>
          {error && <p>{error.message}</p>}
        </form>
      </section>
    );
  }
}

export default compose(withFirebase, withRouter)(SignInForm);
