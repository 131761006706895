import React, { Component } from "react";

class WhyContainer extends Component {
  render() {
    return (
      <section className={`container-fluid`}>
        <section className={`row`}>
          <section className="col-12">WhyContainer</section>
        </section>
      </section>
    );
  }
}

export default WhyContainer;
