import { combineReducers } from "redux";
import unitsReducer from "./unitsReducer";
import neoUnitsReducer from "./neoUnitsReducer";
import viewsReducer from "./viewsReducer";
import developersReducer from "./developers";
import compoundsReducer from "./compounds"
import phasesReducer from "./phases"

const rootReducer = combineReducers({
  unitsReducer,
  neoUnitsState: neoUnitsReducer,
  inventoryViewsState: viewsReducer,
  developers: developersReducer,
  compounds: compoundsReducer,
  phases: phasesReducer
});
export default rootReducer;
