import React, { Component } from 'react';
import styles from './index.module.scss';

import { FormGroup, FormControl, TextField, Button, CircularProgress } from '@material-ui/core';

import DoneIcon from '@material-ui/icons/Done';


import { connect } from "react-redux";
import { handleAddNewPhase } from "../../redux/Actions/phases";
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

class AddNewPhaseForm extends Component {
    state = {
        is_submit: false,
        phase_name: "",
        description: "",
    }
    setPhaseDataToState = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    addNewPhase = (e) => {
        e.preventDefault()
        this.setState({
            is_submit: !this.state.is_submit
        })

        // "id": "UcsTX9ayLOXxXk70rd40",
        // "data": {
        //     "compound": {
        //         "compound_id": "5K0IpHALn5uL9T0CMnLn",
        //         "compound_name": "Milad"
        //     },
        //     "phase_name": "First phase"
        // },
        // "message": "Phase SuccessFully Added"


        this.props.handleAddNewPhase({
            compound_name: localStorage.getItem("compound_name"),
            compound_id: this.props.match.params.compound_id,
            phase_name: this.state.phase_name
        })

    }


    render() {
        const { phase_name, description } = this.state

        let isValid = phase_name.trim() === ''
            || description.trim() === ''

        return (
            <main className={`${styles.phaseForm}`}>
                <h5> New Phase</h5>
                <div className={`${styles.formStyle}`}>
                    <div className={`${styles.dataArea}`}>
                        <FormGroup>
                            <FormControl fullWidth={true}>
                                <TextField id="phase_name" placeholder="Phase Name" label="Phase Name" onChange={this.setPhaseDataToState} />
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormControl fullWidth={true}>
                                <TextField
                                    id="description"
                                    label="description"
                                    multiline
                                    rows={4}
                                    placeholder="description"
                                    variant="outlined"
                                    onChange={this.setPhaseDataToState}
                                />
                            </FormControl>
                        </FormGroup>
                        <FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isValid}
                                id="developer_submitBtn"
                                type="submit"
                                startIcon={
                                    (!this.state.is_submit) ?
                                        <DoneIcon /> : <CircularProgress size={20} />
                                }
                                onClick={this.addNewPhase}>
                                Process
                            </Button>
                        </FormControl>
                    </div>
                </div>
            </main>

        )
    }
}

const mapStateToProps = ({ phases }) => ({
    phases: phases
})
export default compose(
    connect(mapStateToProps, { handleAddNewPhase: handleAddNewPhase }),
    withRouter,
)(AddNewPhaseForm)