import React, { Component, Fragment } from "react";
import { connect } from 'react-redux'
import { compose } from 'redux'
import axios from "axios";

// import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ViewModuleOutlinedIcon from '@material-ui/icons/ViewModuleOutlined';

class InventoryQuickEdit extends Component {
    state = {
        dynamicData: [],
        unitsWithId: [],
        viewUnitsFrom: 0,
        paginationStep: 5,
        anchorEl: null
    }
    componentWillMount = () => {
        console.log(this.state.dynamicData)
        console.log(this.state.dynamicData.length)
        let showSelectedUnits = [];
        if (this.props.selectedUnits.length > 0) {
            this.props.selectedUnits.forEach(index => {
                showSelectedUnits.push(this.props.units[index])
            })
        } else {
            showSelectedUnits = this.props.units;
        }
        let customizedUnits = showSelectedUnits.map(rawUnit => {
            let unitDesign = rawUnit.data.listing.unit_design;
            console.log(unitDesign)
            let propertyType = rawUnit.data.listing.unit_type;
            let builtUpArea = rawUnit.data.listing.bua;
            let bathrooms = rawUnit.data.listing.bathrooms;
            let bedrooms = rawUnit.data.listing.bedrooms;
            let deliveryDate = rawUnit.data.listing.delivery_year;
            return {
                unitDesign,
                propertyType,
                builtUpArea,
                bathrooms,
                bedrooms,
                deliveryDate,
            }
        })
        console.log(customizedUnits)
        this.setState({
            dynamicData: customizedUnits,
            unitsWithId: showSelectedUnits
        })
    }
    inputChangeHandler = (e, i, cell) => {
        let dynamicData = this.state.dynamicData;
        dynamicData[i][cell] = e.target.value;
        console.log(dynamicData)
        this.setState({ dynamicData })
    }
    submitHandler = () => {
        // TODO: perform API calls for only changed items
        // TODO: add all properties to "newListing", not only 3
        this.state.unitsWithId.forEach((unit, i) => {
            // console.log(unit.tableData.id)
            // console.log(this.props.units)
            // let unitId = this.props.units[unit.tableData.id].id;
            // console.log(unitId)
            console.log(this.state.dynamicData[i].bedrooms)
            axios
                .put(`/units/${unit.id}`, {
                    "email": "milad@sakneen.com",
                    "newListing": {
                        "bedrooms": this.state.dynamicData[i].bedrooms,
                        "bathrooms": this.state.dynamicData[i].bathrooms,
                        "bua": this.state.dynamicData[i].bua
                    }
                })
                .then((res) => {
                    console.log(res);
                    console.log(res.data);
                })
            // this.props.changeViewType('basic')
        })
    }
    navPrev = () => {
        this.setState((state, props) => {
            if (state.viewUnitsFrom >= state.paginationStep) {
                return {
                    viewUnitsFrom: state.viewUnitsFrom - state.paginationStep
                };
            } else {
                return {
                    viewUnitsFrom: 0
                };
            }
        });
    }
    navNext = () => {
        this.setState((state, props) => {
            // if (state.viewUnitsFrom <= (state.dynamicData.length - state.paginationStep)) {
            return {
                viewUnitsFrom: state.viewUnitsFrom + state.paginationStep
            };
            // } else {
            // }
        });
    }
    navFirst = () => {
        this.setState({
            viewUnitsFrom: 0
        })
    }
    navLast = () => {
        // console.log(this.state.dynamicData)
        // const length = this.state.dynamicData.length;
        let minus = this.calcMinus(this.state.dynamicData.length, this.state.paginationStep);
        // console.log(minus)
        // console.log(length)
        if (minus < this.state.paginationStep) {
            this.setState({
                viewUnitsFrom: this.state.dynamicData.length - minus
            })
        } else {
            this.setState({
                viewUnitsFrom: minus
            })
        }
    }
    calcMinus = (minus, step) => {
        if (minus > step) {
            // debugger;
            minus -= step;
            this.calcMinus(minus, step);
        }
        return minus
    }
    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };
    handleSelect = (e) => {
        this.setState({
            paginationStep: Number(e.target.dataset.val)
        })
        this.handleClose()
    }
    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    };
    render() {
        // let showSelectedUnits = [];
        // if (this.props.selectedUnits.length > 0) {
        //     this.props.selectedUnits.forEach(index => {
        //         showSelectedUnits.push(this.state.dynamicData[index])
        //     })
        // } else {
        //     showSelectedUnits = this.state.dynamicData;
        // }
        return (
            <Fragment>
                <table>
                    <thead>
                        <tr>
                            {Object.keys(this.state.dynamicData[0]).map((cell, j) => (
                                <th key={cell + j}>
                                    {cell}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.dynamicData.map((unit, i) => {
                            if (i >= this.state.viewUnitsFrom && i < (this.state.viewUnitsFrom + this.state.paginationStep)) {
                                return (
                                    <tr key={i}>
                                        {Object.keys(unit).map((cell, j) => (
                                            <td key={j}>
                                                <TextField
                                                    value={unit[cell]}
                                                    onChange={(e) => this.inputChangeHandler(e, i, cell)}
                                                />
                                            </td>
                                        )
                                        )}
                                    </tr>)
                            }
                            return null;
                        }
                        )}
                    </tbody>
                </table>
                <div>
                    <Button aria-controls="pagination-number-menu"
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        color="primary"
                        startIcon={<ViewModuleOutlinedIcon />}>
                        {this.state.paginationStep} units
                     </Button>
                    <Menu
                        id="pagination-number-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                    >
                        <MenuItem data-val="5" onClick={(event) => this.handleSelect(event)}>5</MenuItem>
                        <MenuItem data-val="10" onClick={(event) => this.handleSelect(event)}>10</MenuItem>
                        <MenuItem data-val="20" onClick={(event) => this.handleSelect(event)}>20</MenuItem>
                    </Menu>
                    <button
                        onClick={this.navFirst}
                    >
                        First
                    </button>
                    <button
                        onClick={this.navPrev}
                    >
                        PREVIOUS
                    </button>
                    <span>
                        {this.state.viewUnitsFrom + 1}
                        -
                        {this.state.viewUnitsFrom + this.state.paginationStep}
                        from
                        {this.state.dynamicData.length}
                    </span>
                    <button
                        onClick={this.navNext}
                    >
                        NEXT
                    </button>
                    <button
                        onClick={this.navLast}
                    >
                        Last
                    </button>
                </div>
                <br />
                <br />
                <br />
                <br />
                <button
                    onClick={this.submitHandler}
                >
                    submit all
                </button>
            </Fragment >
        );
    }
}


const mapStateToProps = state => ({
    units: state.neoUnitsState.neoUnits,
    selectedUnits: state.neoUnitsState.selectedNeoUnits,
})
const mapDispatchToProps = dispatch => ({
    // onChangeView: viewType => dispatch({ type: 'CHANGE_VIEW', payload: viewType }),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(InventoryQuickEdit);

// export default InventoryQuickEdit;