export const RECEIVE_DEVELOPERS = "RECEIVE_DEVELOPERS"
export const IS_LOADING = "IS_LOADING";

export const ADD_DEVELOPER = "ADD_DEVELOPER"
export const SUCCESS_ADD_DEVELOPER = "SUCCESS_ADD_DEVELOPER"
export const FAIL_ADD_DEVELOPER = "FAIL_ADD_DEVELOPER"

export const DELETE_DEVELOPER = "DELETE_DEVELOPER"
export const SUCCESS_DELETE_DEVELOPER = "SUCCESS_DELETE_DEVELOPER"
export const FAIL_DELETE_DEVELOPER = "FAIL_DELETE_DEVELOPER"


export const RESET_DELETE_STATE = "RESET_DELETE_STATE"
export const RESET_ADD_DEVELOPER_STATE = "RESET_ADD_DEVELOPER_STATE"