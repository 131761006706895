import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { compose } from 'redux';
import { withAuthorization } from '../../components/Session';
import { withRouter, Link } from 'react-router-dom';
import { withFirebase } from '../../components/Firebase';

import * as ROUTES from "../../constants/routes"

import MainToolbar from "../../components/MainToolbar";
import AddDeveloperDataModal from "../../components/AddDeveloperDataModal"

import Swal from "sweetalert2"
import withReactContent from 'sweetalert2-react-content'

import MaterialTable, { MTableToolbar } from 'material-table'

import './index.css'


import { connect } from "react-redux"
import {
  fetchDeveloperData,
  handleDeleteDevelopers
} from "../../redux/Actions/developers";

const MySwal = withReactContent(Swal)

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


class Developers extends Component {

  state = {
    value: 0,
  }

  componentDidMount() {
    this.props.fetchDeveloperData()

  }
  changeTabs = (e, newValue) => {
    this.setState({
      value: newValue
    })
  }
  render() {

    const { value } = this.state;
    let { developers } = this.props


    let allDevelopers = developers && developers.developers

    let developerData = []

    if (allDevelopers && allDevelopers.length > 0) {
      developerData = allDevelopers.map(developer => ({
        id: developer.id,
        developer_name: developer.developer_name,
        card_icon_url: developer.card_icon_url,
        page_icon_url: developer.page_icon_url,
        description: developer.description
      }))
    }



    const dynamicColumns = [
      {
        title: "Developer", field: "page_icon_url",
        render: rowData => <Link to={`${ROUTES.DEVELOPERS}/${rowData.id}`}>
          <img src={rowData.page_icon_url} style={{ width: 50 }} alt={rowData.id}
            onClick={()=>{
              localStorage.setItem("developer_name",rowData.developer_name)
            }}
          />
        </Link>
      },
      {
        title: "Developer Name",
        field: "developer_name",
      },
      {
        title: "Developer Name",
        field: "developer_name",
      }
    ]

    if (developers&&developers.deleted) {

      if (developers.deleted.type) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: developers.deleted.message,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: developers.deleted.message,
          footer: '<a href>Why do I have this issue?</a>'
        })
      }
    }

    if (developers&&developers.added) {

      if (developers.added.type) {
        // const developer = developers.developers[developers.developers.length - 1]
        MySwal.fire({
          icon: 'success',
          title: 'Success!',
          text: developers.added.message,
          //     footer: <a href={`${ROUTES.DEVELOPERS}/${developer.id}`} >
          //       Add New Compound
          // </a>
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: developers.added.message,
          footer: '<a href>Why do I have this issue?</a>'
        })
      }
    }

    return (
      <section className="developer">
        <div className="root">
          <AppBar position="static">
            <Tabs value={value} onChange={this.changeTabs} aria-label="simple tabs example">
              <Tab label="Developers" {...a11yProps(0)} />
              <Tab label="Individual" {...a11yProps(1)} />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <MaterialTable
              isLoading={developers && !developers.isLoading}
              columns={dynamicColumns}
              data={developerData}
              components={{
                Toolbar: props => (
                  <>
                    <MTableToolbar {...props} />
                    <MainToolbar
                      tabs={[<AddDeveloperDataModal type="developer" />]}
                    />
                  </>
                )
              }}
              actions={[
                {
                  icon: 'delete',
                  tooltip: 'Save User',
                  onClick: (event, rowData) => {
                    Swal.fire({
                      title: 'Are you sure?',
                      text: "You won't be able to revert this!",
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                      if (result.value) {
                        this.props.handleDeleteDevelopers(rowData)
                      }
                    })
                  }
                }
              ]}
              options={{
                actionsColumnIndex: -1
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MaterialTable
              columns={[
                { title: 'Adı', field: 'name' },
                { title: 'Soyadı', field: 'surname' },
                { title: 'Doğum Yılı', field: 'birthYear', type: 'numeric' },
                { title: 'Doğum Yeri', field: 'birthCity', lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' } }
              ]}
              data={[{ name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 }]}
              title="Individual"
            />
          </TabPanel>
        </div>

      </section>
    );
  }
}


const condition = (authUser) => !!authUser;

const mapStateToProps = ({ developers }) => ({
  developers: developers
})

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, {
    fetchDeveloperData: fetchDeveloperData,
    handleDeleteDevelopers: handleDeleteDevelopers,
  }),
  withAuthorization(condition)
)(Developers);