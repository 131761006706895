import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ViewModuleOutlinedIcon from '@material-ui/icons/ViewModuleOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function InventoryChangeViewMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (e) => {
    // props.changeViewType(e.target.dataset.val);
    props.onChangeView(e.target.dataset.val);
    handleClose()
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button aria-controls="simple-menu"
      aria-haspopup="true" 
      onClick={handleClick}
      color="primary"
      startIcon={<ViewModuleOutlinedIcon />}
      endIcon={<KeyboardArrowDownIcon />}>
        View
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem data-val="basic" onClick={(event) => handleSelect(event)}>Basic</MenuItem>
        <MenuItem data-val="financial" onClick={(event) => handleSelect(event)}>Financial</MenuItem>
        <MenuItem data-val="construction" onClick={(event) => handleSelect(event)}>Construction</MenuItem>
        <MenuItem data-val="image" onClick={(event) => handleSelect(event)}>Image</MenuItem>
      </Menu>
    </>
  );
}

const mapStateToProps = state => ({
  // units: state.unitState.units,
})
const mapDispatchToProps = dispatch => ({
  onChangeView: viewType => dispatch({ type: 'CHANGE_VIEW', payload: viewType }),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(InventoryChangeViewMenu);
// export default InventoryChangeViewMenu;
