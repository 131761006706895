import React, { Component, Fragment } from "react";
import InventorySheetUpload from "../InventorySheetUpload";
import InventorySheetFormat from "../InventorySheetFormat";
import InventoryFinancingForm from "../InventoryFinancingForm";
import InventoryCompletePopup from "../InventoryCompletePopup";
import InventoryIncompletePopup from "../InventoryIncompletePopup";
import { readInventoryFile } from "../../utils/uploadScript";
import axios from "axios";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import styled from "styled-components";
import "./index.css";

import { unitSchema } from "../../utils/validation";
import Swal from "sweetalert2";

const ModalStyled = styled(Modal)`
  display: "flex";
  align-items: "center";
  justify-content: "center";
`;

class ModalContainerUploadInventory extends Component {
  state = {
    open: false,
    viewSection: 1,
    fileData: [],
    sheetformatArray: [],
    mapping: [],
    isComplete: false,
    loading: false,
  };

  handleOpen = () => {
    this.setState({
      open: true,
      loading: false,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  changeViewSectionHandler = () => {
    this.setState((prevState) => ({
      viewSection: prevState.viewSection + 1,
    }));
  };
  readingFileData = (selectedFile) => {
    readInventoryFile(selectedFile, this);
  };

  sheetFormat = (sheetformatArray, mapping) => {
    this.setState({
      sheetformatArray: sheetformatArray,
      mapping: mapping,
    });
  };

  submitInventory = (event, fileData) => {
    event.preventDefault();

    const units = fileData.map((unit) => {
      let arr = this.state.sheetformatArray.map((el) =>
        //  console.log(unit[el])
        typeof unit[el] === "string" && unit[el].toLowerCase() === "n"
          ? false
          : typeof unit[el] === "string" && unit[el].toLowerCase() === "y"
          ? true
          : unit[el]
      );
      let newArr = {};
      for (let index = 0; index < arr.length; index++) {
        newArr[this.state.mapping[index]] = arr[index];
      }
      return Object.assign({}, newArr);
    });
    const sellerData = {
      seller_name: "Milad",
      seller_email: "milad@gmail.com",
    };

    const validation = units.map((unit) => {
      const err = unitSchema.validate(unit);
      return err.error;
    });
    // console.log("validation[0].message ", validation);
    let hasError = false;
    for (let i = 0; i < validation.length; i++) {
      if (validation[i] !== undefined) {
        hasError = true;
        break;
      }
    }
    if (!hasError) {
      axios
        .post(`/units`, { units: units, sellerData })
        .then((res) => {
          console.log(res);
          console.log(res.data);
          if(res.status === 201) {
            this.setState({
              uploadComplete: !this.state.uploadComplete,
            })
            this.handleClose();
            Swal.fire(
              'Successful',
              'File has been uploaded',
              'success'
            )
            this.setState({
              viewSection: 1,
            })
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
      console.log(units);
      this.setState({
        loading: !this.state.loading,
      })
    } else {
      this.handleClose();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${validation
          .filter((el, index) => el && el.message)
          .map((m) => m.message)}`,
        footer: "<a href>Why do I have this issue?</a>",
      });
      this.setState({
        viewSection: 1,
      })
    }
  };
  render() {
    const { open } = this.state;
    return (
      <Fragment>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={this.handleOpen}
        >
          Add new inventory
        </Button>
        <ModalStyled
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="paper">
              {(this.state.loading)?
              <div className="loading">
                <CircularProgress size={80} />
              </div>: ""}
              <InventorySheetUpload readingFileData={this.readingFileData} />
              {this.state.viewSection === 1 && (
                <InventorySheetFormat
                  changeView={this.changeViewSectionHandler}
                  sheetFormat={this.sheetFormat}
                />
              )}
              {this.state.viewSection === 2 && (
              <InventoryFinancingForm
                  changeView={this.changeViewSectionHandler}
                />
              )}
              {this.state.viewSection === 3 && (
                (!this.state.isComplete)?
                <>
                  <InventoryCompletePopup
                    changeView={this.changeViewSectionHandler}
                  />
                  <div className="sub-btn">
                    <Button
                      onClick={(e) => {
                        this.submitInventory(e, this.state.fileData);
                      }}
                      variant="contained"
                      color="primary">
                      <DoneIcon />
                    </Button>
                  </div>
                </>:
                <>
                  <InventoryIncompletePopup
                    changeView={this.changeViewSectionHandler}
                  />
                  <div className="sub-btn">
                    <Button 
                      onClick={(e) => {
                        this.submitInventory(e, this.state.fileData);
                      }}
                      variant="contained" 
                      color="primary">
                      <DoneIcon />
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Fade>
        </ModalStyled>
      </Fragment>
    );
  }
}

export default ModalContainerUploadInventory;
