import React, { Component } from 'react';
import styles from './index.module.scss';

import { FormGroup, FormControl, TextField, Divider, Grid, Button, CircularProgress,Tooltip, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';


import { connect } from "react-redux";
import { handleAddNewDeveloper } from "../../redux/Actions/developers";
import { compose } from 'redux';
import { withFirebase } from '../Firebase';

class AddNewDeveloperForm extends Component {
    state = {
        dialogOpen: false,
        is_submit: false,
        developer_name: "",
        description: "",
        page_icon: "",
        card_icon: "",
        card_icon_Src: '',
        page_icon_Src: '',
    }
    setDeveloperDataToState = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    catchIcons = (e) => {
        // console.log(e.target.files[0]);

        const reader = new FileReader();

        let url;
        if (e.target.files[0]) {
            url = reader.readAsDataURL(e.target.files[0]);
        }
        const key = `${e.target.id}_Src`
        reader.onloadend = function (e) {
            this.setState({
                [key]: [reader.result]
            })
        }.bind(this);
        this.setState({
            [e.target.id]: e.target.files[0],
        })

        console.log("add new developer form url :", url);

    }
    addNewDeveloper = (e) => {
        e.preventDefault()
        this.setState({
            is_submit: !this.state.is_submit
        })

        this.props.firebase.uploadDeveloperIcons(this.state.developer_name, this.state.page_icon, this.state.card_icon).then(urls => {
            const newDeveloper = {
                developer_name: this.state.developer_name,
                description: this.state.description,
                page_icon_url: urls.card_icon_url,
                card_icon_url: urls.page_icon_url,
            }
            this.props.handleAddNewDeveloper(newDeveloper)            
        })
    }

    handleOpenDialog = () => {
        this.setState({
            dialogOpen: true,
        });
    };

    handleCloseDialog = () => {
        this.setState({
            dialogOpen: false,
        });
    };

    render() {        
        const { page_icon, card_icon, developer_name, description, dialogOpen } = this.state

        let isValid = page_icon === ''
            || card_icon === ''
            || developer_name.trim() === ''
            || description.trim() === ''

        return (
            <main className={`${styles.developerForm}`}>
                <h5> New Developer</h5>
                <div className={`${styles.formStyle}`}>
                    <FormControl className={`${styles.photoArea}`} fullWidth={true}>
                        <Grid container justify="center" className={`${styles.fixAreaPos}`}>
                            <Grid item xs={6}>
                                <input accept="image/*" id="page_icon" type="file" onChange={this.catchIcons} />
                                <label htmlFor="page_icon">
                                <div className={`${styles.fixAspectRatio}`}>
                                    <img src={
                                    this.state.page_icon_Src
                                        ? this.state.page_icon_Src
                                        : require("../../assets/camera-icon.svg")} alt="upload-icon" />
                                </div>
                                <Typography variant="caption" component="h6" color="primary">Page icon</Typography>
                                </label>
                                <Tooltip title="this appear in your page in marketplace" arrow>
                                    <img src={require("../../assets/help.svg")} />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={6}>
                                <input accept="image/*" id="card_icon" type="file" onChange={this.catchIcons} />
                                <label htmlFor="card_icon">
                                <div className={`${styles.fixAspectRatio}`}>
                                    <img src={
                                        this.state.card_icon_Src
                                            ? this.state.card_icon_Src
                                            : require("../../assets/camera-icon.svg")} alt="upload-icon" />
                                </div>
                                <Typography variant="caption" component="h6" color="primary">Card icon</Typography>
                                </label>
                                <Tooltip title="Add" arrow>
                                    <img src={require("../../assets/help.svg")} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <Divider />
                    <div className={`${styles.dataArea}`}>
                        <FormGroup>
                            <FormControl fullWidth={true}>
                                <TextField id="developer_name" placeholder="Developer Name" label="Developer Name" onChange={this.setDeveloperDataToState} />
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormControl fullWidth={true}>
                                <TextField
                                    id="description"
                                    label="description"
                                    multiline
                                    rows={4}
                                    placeholder="description"
                                    variant="outlined"
                                    onChange={this.setDeveloperDataToState}
                                />
                            </FormControl>
                        </FormGroup>
                        <FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isValid}
                                id="developer_submitBtn"
                                type="submit"
                                startIcon={
                                    (!this.state.is_submit) ?
                                        <DoneIcon /> : <CircularProgress size={20} />
                                }
                                onClick={this.addNewDeveloper}>
                                Process
                            </Button>
                        </FormControl>
                    </div>
                    <Button variant="text" color="primary" onClick={this.handleOpenDialog}>
                        <img src={require("../../assets/help-tutorial-btn.svg")} alt="help button" />
                    </Button>
                    <Dialog
                    open={dialogOpen}
                    onClose={this.handleCloseDialog}
                    scroll={'body'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    maxWidth="xl">
                    <Grid container alignItems="baseline">
                        <Grid item xs={11}>
                            <DialogTitle id="scroll-dialog-title">Developer Page Marketplace</DialogTitle>
                        </Grid>
                        <Grid item xs={1}>
                            <Button onClick={this.handleCloseDialog} color="primary">
                                <CloseIcon />
                            </Button>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <img 
                        src={require("../../assets/developer-tutorial.png")} 
                        alt="developer tutorial" 
                        style={{maxWidth: "100%"}}/>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleCloseDialog} color="primary">
                        Close
                    </Button>
                    </DialogActions>
                </Dialog>
                </div>
            </main>

        )
    }
}

const mapStateToProps = ({ developers }) => ({
    developers: developers
  })
export default compose(    
    connect(mapStateToProps, { handleAddNewDeveloper: handleAddNewDeveloper }),
    withFirebase
)(AddNewDeveloperForm)