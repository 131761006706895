import React, { Component } from "react";

class HowContainer extends Component {
  render() {
    return (
      <section className={`container-fluid`}>
        <section className={`row`}>
          <section className="col-12">HowContainer</section>
        </section>
      </section>
    );
  }
}

export default HowContainer;
