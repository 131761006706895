import React, { Component } from "react";
import MaterialTable, { MTableToolbar } from 'material-table';
import { connect } from 'react-redux'
import { compose } from 'redux'
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Toolbar from '../Toolbar';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';


class TableView extends Component {
  state = {
    dynamicData: [],
    filterUnitDesign: false,
    filterPropertyType: false,
    filterBuiltUpArea: false,
    filterBathrooms: false,
    filterBedrooms: false,
    filterDeliveryDate: false,
    isLoading: true,
  }

  componentDidMount = () => {
    axios
      .get(`/units`)
      .then((res) => {
        let result = { ...res };
        console.log(result.data);
        let rawUnits = result.data.slice(0, 20);
        this.props.onSetNeoUnits(rawUnits);
        let customizedUnits = rawUnits.map(rawUnit => {
          let unitDesign = rawUnit.data.listing.unit_design;
          // console.log(unitDesign)
          let propertyType = rawUnit.data.listing.unit_type;
          let builtUpArea = rawUnit.data.listing.bua;
          let bathrooms = rawUnit.data.listing.bathrooms;
          let bedrooms = rawUnit.data.listing.bedrooms;
          let deliveryDate = rawUnit.data.listing.delivery_year;
          return {
            unitDesign,
            propertyType,
            builtUpArea,
            bathrooms,
            bedrooms,
            deliveryDate,
          }
        })
        this.setState({
          isLoading: false,
          dynamicData: customizedUnits
        })
      });
  }
  addFilter = (type) => {
    this.setState({
      filter: true,
      [type]: true
    })
  }
  editAll = () => {
  }
  selectBoxHandler = (rows, t) => {
    console.log(rows, t);
    let IDs = rows.map(row => row.tableData.id);
    this.props.onModifySelectedUnits(IDs)
  }
  unitDesignInputChangeHandler = (e, i) => {
    let dynamicData = this.state.dynamicData;
    // console.log(dynamicData)
    dynamicData[i].unitDesign = e.target.value;
    console.log(dynamicData)
    this.setState({ dynamicData })
  }
  render() {
    let options = {
      selection: true,
      headerStyle: {
        fontWeight: 'bold',
      }
    }
    if (this.state.filter === true) {
      options = {
        selection: true,
        filtering: true
      }
    }

    // let customizedUnits = this.props.neoUnits.map(rawUnit => {
    // let customizedUnits = this.state.dynamicData.map(rawUnit => {
    //   let unitDesign = rawUnit.data.listing.unit_design;
    //   // console.log(unitDesign)
    //   let propertyType = rawUnit.data.listing.unit_type;
    //   let builtUpArea = rawUnit.data.listing.bua;
    //   let bathrooms = rawUnit.data.listing.bathrooms;
    //   let bedrooms = rawUnit.data.listing.bedrooms;
    //   let deliveryDate = rawUnit.data.listing.delivery_year;
    //   return {
    //     unitDesign,
    //     propertyType,
    //     builtUpArea,
    //     bathrooms,
    //     bedrooms,
    //     deliveryDate,
    //   }
    // })

    // const dynamicColumns = [
    //   { title: 'unit design', field: 'unitDesign', filtering: this.state.filterUnitDesign },
    //   { title: 'property type', field: 'propertyType', filtering: this.state.filterPropertyType },
    //   { title: 'built up area', field: 'builtUpArea', filtering: this.state.filterBuiltUpArea },
    //   { title: '#Bathrooms', field: 'bathrooms', type: 'numeric', filtering: this.state.filterBathrooms },
    //   { title: '#Bedrooms', field: 'bedrooms', type: 'numeric', filtering: this.state.filterBedrooms },
    //   { title: 'Delivery Date', field: 'deliveryDate', filtering: this.state.filterDeliveryDate }
    // ];

    const quickEditDynamicColumns = [
      {
        title: 'unit design', field: 'unitDesign',
        filtering: this.state.filterUnitDesign,
        render: rowData => <input
          value={rowData.unitDesign}
          onChange={(e) => this.unitDesignInputChangeHandler(e, rowData.tableData.id)}
          // onChange={(e) => {e.persist();console.log(e.target, rowData)}}
          style={{ width: 40 }} />
      },
      { title: 'property type', field: 'propertyType', filtering: this.state.filterPropertyType },
      { title: 'built up area', field: 'builtUpArea', filtering: this.state.filterBuiltUpArea },
      { title: '#Bathrooms', field: 'bathrooms', type: 'numeric', filtering: this.state.filterBathrooms },
      { title: '#Bedrooms', field: 'bedrooms', type: 'numeric', filtering: this.state.filterBedrooms },
      { title: 'Delivery Date', field: 'deliveryDate', filtering: this.state.filterDeliveryDate }
    ];

    const collapsedAreaColumns = [
      { title: 'Unit number', field: 'unitNumber' },
      { title: 'Status', field: 'status' },
      { title: 'Available', field: 'available' },
      { title: 'Sold', field: 'sold' },
    ];

    // const eye = <VisibilityIcon />
    const eyeOff = <VisibilityOffIcon />;
    const availableIcon = <CheckIcon />;
    const unAvailableIcon = <CloseIcon />;

    const collapsedAreaData = [
      { unitNumber: '1', status: eyeOff, available: availableIcon, sold: 'yes' },
      { unitNumber: '1', status: eyeOff, available: availableIcon, sold: 'yes' },
      { unitNumber: '1', status: eyeOff, available: unAvailableIcon, sold: 'yes' },
      { unitNumber: '1', status: eyeOff, available: availableIcon, sold: 'yes' },
    ];

    const collapsedAreaStyle = {
      margin: "1.5rem 0",
    }

    const tableHeader = <></>;

    return (
      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            title={tableHeader}
            isLoading={this.state.isLoading}
            components={{
              Toolbar: props => (
                <>
                  <MTableToolbar {...props} />
                  <Toolbar
                    changeViewType={this.props.changeViewType}
                    addFilter={this.addFilter}
                    removeFilter={this.props.removeFilter}
                  />
                </>
              ),
            }}
            // columns={dynamicColumns}
            columns={quickEditDynamicColumns}
            // data={customizedUnits}
            data={this.state.dynamicData}
            options={options}
            onSelectionChange={(rows, t) => { this.selectBoxHandler(rows, t) }}
            editable={{
              // onRowAdd: newData =>
              //   new Promise((resolve, reject) => {
              //     setTimeout(() => {
              //       {
              //         const data = this.state.data;
              //         data.push(newData);
              //         this.setState({ data }, () => resolve());
              //       }
              //       resolve()
              //     }, 1000)
              //   }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      const data = this.state.data;
                      const index = data.indexOf(oldData);
                      data[index] = newData;
                      this.setState({ data }, () => resolve());
                    }
                    resolve()
                  }, 1000)
                }),
              // onRowDelete: oldData =>
              //   new Promise((resolve, reject) => {
              //     setTimeout(() => {
              //       {
              //         let data = this.state.data;
              //         const index = data.indexOf(oldData);
              //         data.splice(index, 1);
              //         this.setState({ data }, () => resolve());
              //       }
              //       resolve()
              //     }, 1000)
              //   }),
            }}
            detailPanel={rowData => {
              return (
                <Grid container justify="center">
                  <Grid item xs={6}>
                    <MaterialTable
                      style={collapsedAreaStyle}
                      columns={collapsedAreaColumns}
                      data={collapsedAreaData}
                      components={{
                        Pagination: props => (
                          <></>
                        ),
                      }}
                      options={{
                        toolbar: false,
                        headerStyle: {
                          fontWeight: 'bold',
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              )
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  neoUnits: state.neoUnitsState.neoUnits,
})
const mapDispatchToProps = dispatch => ({
  onModifySelectedUnits: IDs => dispatch({ type: 'MODIFY_NEO_UNITS', payload: IDs }),
  onSelectNeoUnit: id => dispatch({ type: 'SELECT_NEO_UNIT', payload: id }),
  onUnselectNeoUnit: id => dispatch({ type: 'UNSELECT_NEO_UNIT', payload: id }),
  onSetNeoUnits: units => dispatch({ type: 'SET_NEO_UNITS', payload: units }),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(TableView);