import {
    FETCH_DEVELOPER_COMPOUNDS,
    IS_LOADING,
    FAIL_FETCH_DEVELOPER_COMPOUNDS,
    SUCCESS_FETCH_DEVELOPER_COMPOUNDES,

    ADD_COMPOUND,
    SUCCESS_ADD_COMPOUND,
    FAIL_ADD_COMPOUND,
    RESET_ADD_COMPOUND_STATE,

    DELETE_COMPOUND,
    SUCCESS_DELETE_COMPOUND,
    FAIL_DELETE_COMPOUND,
    RESET_DELETE_STATE,
} from "../Constants/compounds";

import axios from "axios";

const fetchDeveloperCompounds = (compounds) => ({
    type: FETCH_DEVELOPER_COMPOUNDS,
    compounds,
})
const loading = (isLoading) => ({
    type: IS_LOADING,
    isLoading
})

const failFetchDeveloperCompounds = (compoundsFetched) => ({
    type: FAIL_FETCH_DEVELOPER_COMPOUNDS,
    compoundsFetched
})

const successFetchDeveloperCompounds = (compoundsFetched) => ({
    type: SUCCESS_FETCH_DEVELOPER_COMPOUNDES,
    compoundsFetched
})


const addCompound = (compound) => ({
    type: ADD_COMPOUND,
    compound,
})

const successAddCompound = (success) => ({
    type: SUCCESS_ADD_COMPOUND,
    success,
})

const failedAddCompound = (error) => ({
    type: FAIL_ADD_COMPOUND,
    error,
})

const resetAddState = (added) => ({
    type: RESET_ADD_COMPOUND_STATE,
    added
})

const deleteCompound = (id) => ({
    type: DELETE_COMPOUND,
    id,
})

const successDeleteCompound = (success) => ({
    type: SUCCESS_DELETE_COMPOUND,
    success,
})

const failedDeleteCompound = (error) => ({
    type: FAIL_DELETE_COMPOUND,
    error,
})
const resetDeleteState = (deleted) => ({
    type: RESET_DELETE_STATE,
    deleted
})

export const handleFetchDeveloperCompounds = (developerId) => async (dispatch) => {
    dispatch(loading(false))
    axios.get(`/compounds/${developerId}`)
        .then(res => {
            const compounds = res.data.allCompounds.map(compound => ({
                id: compound.id,
                ...compound.data
            }))
            dispatch(fetchDeveloperCompounds(compounds))
            dispatch(successFetchDeveloperCompounds({
                type: true,
            }))
            dispatch(loading(true))
        }).catch(error => {
            console.log(error);
            dispatch(failFetchDeveloperCompounds({
                type: false,
                message: "Failed to load data"
            }))
            dispatch(loading(true))
        })

}


export const handleAddNewCompound = (newCompound) => (dispatch) => {

    axios.post('/compounds', { ...newCompound })
        .then(res => {
            console.log(res)
            dispatch(successAddCompound({
                type: true,
                message: "Successfully Added new Compound"
            }))
            let compoundData = {
                id: res.data.id,
                ...res.data.data
            }
            dispatch(addCompound(compoundData))
            dispatch(resetAddState(null))
        }).catch(error => {
            console.log(error);
            dispatch(failedAddCompound({
                type: false,
                message: "Failed to add new Compound"
            }))
            dispatch(resetAddState(null))
        })

}


export const handleDeleteCompound = (compound) => async (dispatch) => {
    dispatch(deleteCompound(compound.id))

    axios.delete(`/compounds/historical/${compound.id}`)
        .then(res => {
            console.log(res);

            dispatch(successDeleteCompound({
                type: true,
                message: `Successfully delete compound: ${compound.compound_name}`
            }))

            dispatch(resetDeleteState(null))

        }).catch(error => {
            console.log(error);

            dispatch(failedDeleteCompound({
                type: true,
                message: `Failed to delete compound: ${compound.compound_name}`
            }))

            dispatch(addCompound(compound))

            dispatch(resetDeleteState(null))
            
        })
}