import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyDB-eauEIRh03d0mBwi6CSwurFyK3lxHAM",
  authDomain: "sakneen-test.firebaseapp.com",
  databaseURL: "https://sakneen-test.firebaseio.com",
  projectId: "sakneen-test",
  storageBucket: "sakneen-test.appspot.com",
  messagingSenderId: "465624459938",
  appId: "1:465624459938:web:b85e9760dc174594b145fb",
  measurementId: "G-6QWREVGB9H",
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();

    this.storage = app.storage();
    this.storageRef = app.storage().ref("/developers");

    this.firestore = app.firestore();
    //users
    this.usersRef = app.firestore().collection("ControlPanelUsers");

    //Units
    this.unitsRef = app.firestore().collection("TestingUnits");
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***
  user = (uid) => this.db.ref(`users/${uid}`);
  users = () => this.db.ref("users");

  //create user in firestore database
  createNewUser = (uid, fullName, email) => {
    return this.usersRef.doc(uid).set({
      fullName: fullName,
      email: email,
      userRole: "1", //not admin user if userRole is (0) will be admin user can access control panel
    });
  };

  //Inventory Upload
  uploadInventory = (data) => {
    return this.unitsRef.doc().set(data);
  };


  uploadDeveloperIcons = async (developer_name, page_icon, card_icon) => {

    let pageIcon = await this.storageRef.child(developer_name).child(page_icon.name).put(page_icon);
    let cardIcon = await this.storageRef.child(developer_name).child(card_icon.name).put(card_icon);

    let urls;
    if (pageIcon && cardIcon) {
      const page_icon_url = await this.storageRef.child(developer_name).child(page_icon.name).getDownloadURL()
      const card_icon_url = await this.storageRef.child(developer_name).child(card_icon.name).getDownloadURL()
      urls = {
        page_icon_url,
        card_icon_url
      }
    }
    return urls
  }

  uploadCompoundDocuments = async (developer_name, compound_name,compoundDocuments) => {
    const now = Date.now();
    
    let pageIcon = await this.storageRef.child(developer_name).child("compounds").child(compound_name)
      .child(now + compoundDocuments.page_icon.name).put(compoundDocuments.page_icon);


    let cardIcon = await this.storageRef.child(developer_name).child("compounds").child(compound_name)
      .child(now + compoundDocuments.card_icon.name).put(compoundDocuments.card_icon);


    let brochure = await this.storageRef.child(developer_name).child("compounds").child(compound_name)
    .child(now + compoundDocuments.brochure.name).put(compoundDocuments.brochure);
    
    let video = await this.storageRef.child(developer_name).child("compounds").child(compound_name)
    .child(now + compoundDocuments.video.name).put(compoundDocuments.video);
    

    let urls;
    if (pageIcon && cardIcon && brochure && video) {
      const page_icon_url = await this.storageRef.child(developer_name).child("compounds").child(compound_name).child(now + compoundDocuments.page_icon.name).getDownloadURL()

      const card_icon_url = await this.storageRef.child(developer_name).child("compounds").child(compound_name).child(now + compoundDocuments.card_icon.name).getDownloadURL()


      const brochure_url = await this.storageRef.child(developer_name).child("compounds").child(compound_name).child(now + compoundDocuments.brochure.name).getDownloadURL()

      const video_url = await this.storageRef.child(developer_name).child("compounds").child(compound_name).child(now + compoundDocuments.video.name).getDownloadURL()


      urls = {
        page_icon_url,
        card_icon_url,
        brochure_url,
        video_url,
      }
    }
    

    let photosuploaded = [];

    for (let index = 0; index < compoundDocuments.photos.length; index++) {
      photosuploaded.push(compoundDocuments.photos[index]);
      await this.storageRef.child(developer_name).child("compounds").child(compound_name).child("gallery").child(now + compoundDocuments.photos[index].name).put(compoundDocuments.photos[index])
    }

    let photosUrls = []
    if (photosuploaded.length > 0) {
      for (let index = 0; index < compoundDocuments.photos.length; index++) {
        const purl = await this.storageRef.child(developer_name).child("compounds").child(compound_name).child("gallery").child(now + compoundDocuments.photos[index].name).getDownloadURL();
        photosUrls.push(purl)
      }
    }
    return {
      ...urls,
      photosUrls
    }
  }
}

export default Firebase;
