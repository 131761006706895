import React, { Component, Fragment } from "react";
import styles from './index.module.scss';


import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

class InventorySheetFormat extends Component {
  state = {
    isSelectFormat: false,
  }

  changeViewHandler = () => {
    this.props.changeView();
  };
  handleOnChange = (e) => {
    const choice = e.target.value;
    if (choice === "1") {
      const arrayOfCols = [
        "Developer",
        "Compound",
        "Phase",
        "Unit Design",
        "Unit ID",
        "Total Price",
        "Unit Type",
        "Delivery Month",
        "Delivery Year",
        "Ready to Move?",
        "Built Up Area",
        "Number of Bedrooms",
        "Number of Bathrooms",
        "Finishing Type",
        "Land Area",
        "Garden?",
        "Garage?",
      ];
      const maping = [
        "developer",
        "compound",
        "phase",
        "unit_design",
        "unit_id",
        "total_price",
        "unit_type",
        "delivery_month",
        "delivery_year",
        "ready_to_move",
        "bua",
        "bedrooms",
        "bathrooms",
        "finishing_type",
        "land",
        "garden",
        "garage",
      ];
      this.props.sheetFormat(arrayOfCols, maping);
      this.setState({
        isSelectFormat: true,
      })
    }
  };
  render() {
    return (
      <Fragment>
        <Grid container justify="center" spacing={5}>
          <Grid item>
            <FormControl>
              <InputLabel style={{margin: 0}} htmlFor="native-simple">Select Format</InputLabel>
              <Select
                native
                onChange={this.handleOnChange}>
                <option aria-label="None" value="" />
                <option value={1}>Sakneen Format</option>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* <div>InventorySheetFormat</div>
        <select onChange={this.handleOnChange}>
          <option defaultValue="none">Choose Format</option>
          <option value="1">Sakneen Format</option>
        </select> */}
        <div className={`${styles.nextBtn}`}>
          <Button 
            onClick={this.changeViewHandler}
            variant="contained" 
            color="primary"
            disabled={!this.state.isSelectFormat}>
            <NavigateNextIcon />
          </Button>
        </div>
        {/* <div style={{textAlign: "right"}}>
          <button onClick={this.changeViewHandler}>NEXT</button>
        </div> */}
      </Fragment>
    );
  }
}

export default InventorySheetFormat;
