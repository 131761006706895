import { UNITS_SET } from "../Constants/userConstants";

const Units = (state = [], action) => {
  switch (action.type) {
    case UNITS_SET:
      return {
        ...state,
        units: action.units,
      };
    default:
      return state;
  }
};

export default Units;
