import React from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 12,
    },
}));

function MainToolbar(props) {
    const classes = useStyles();
    return (
        <main className={classes.content}>
            <div style={{ backgroundColor: "#f9f9f9", lineHeight: "70px" }}>
                <Grid container justify="flex-end">
                    <Grid item>
                        {
                            props.tabs && props.tabs.map((Tab, i) => <section key={`${i} ${Date.now()}`}>
                                {Tab}
                            </section>)
                        }
                    </Grid>
                </Grid>
            </div>
        </main>
    );
}

export default MainToolbar;