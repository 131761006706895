import React, {  Fragment } from "react";
import { connect } from 'react-redux'
import { compose } from 'redux'

import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TableView from "../../components/TableView";
import InventoryFinancialView from "../../components/InventoryFinancialView";
import InventoryConstructionView from "../../components/InventoryConstructionView";
import InventoryImageView from "../../components/InventoryImageView";
import InventoryQuickEdit from "../../components/InventoryQuickEdit";
import { withRouter } from "react-router-dom";
import { withAuthorization } from "../../components/Session";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  heading: {
    margin: "1.5rem 0",
  }
}));

function Inventory(props) {
  // const [viewType, setViewType] = useState('basic');
  // const [filterType, addFilterType] = useState([]);
  const classes = useStyles();
  const changeViewType = (type) => {
    props.onChangeView(type);
  };
  return (
    <Fragment>
      <Typography className={classes.heading} variant="h4">Inventory</Typography>
      {props.viewType === "basic" && <TableView changeViewType={changeViewType} />}
      {props.viewType === "financial" && (
        <InventoryFinancialView changeViewType={changeViewType} />
      )}
      {props.viewType === "construction" && (
        <InventoryConstructionView changeViewType={changeViewType} />
      )}
      {props.viewType === "image" && (
        <InventoryImageView changeViewType={changeViewType} />
      )}
      {props.viewType === "quickEdit" && (
        <InventoryQuickEdit changeViewType={changeViewType} />
      )}
    </Fragment>
  );
}

const mapStateToProps = state => ({
  viewType: state.inventoryViewsState.viewType,
})
const mapDispatchToProps = dispatch => ({
  onChangeView: viewType => dispatch({ type: 'CHANGE_VIEW', payload: viewType }),
  // onChangeView: viewType => dispatch({ type: 'CHANGE_VIEW', payload: viewType }),
})

const condition = (authUser) => !!authUser;
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthorization(condition),
  withRouter)
  (Inventory);

// export default compose(withAuthorization(condition), withRouter)(Inventory);
