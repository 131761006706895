import React from "react";
import { connect } from 'react-redux'
import { compose } from 'redux'

import InventoryChangeViewMenu from "../InventoryChangeViewMenu";
import InventoryAddFilter from "../InventoryAddFilter";
import ModalContainerUploadInventory from "../ModalContainerUploadInventory";

import { makeStyles } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 12,
    // padding: theme.spacing(3),
  },
}));

function Toolbar(props) {
  const classes = useStyles();
  return (
    <main className={classes.content}>
      {/* <div className={classes.toolbar} /> */}
      <div style={{ backgroundColor: "#f9f9f9", lineHeight: "70px" }}>
        <Grid container justify="flex-end">
          <Grid item>
            {props.selectedNeoUnits.length > 0 &&
              <Button 
                color="secondary"
                startIcon={<DeleteIcon />}>
                delete
              </Button>
            }
            <InventoryChangeViewMenu changeViewType={props.changeViewType} />
            <InventoryAddFilter addFilter={props.addFilter} />
            <Button color="primary"
              onClick={() => props.changeViewType('quickEdit')}
              startIcon={<EditOutlinedIcon />}>
              quick edit
            </Button>
            <ModalContainerUploadInventory />
          </Grid>
        </Grid>
      </div>
    </main>
  );
}

const mapStateToProps = state => ({
  selectedNeoUnits: state.neoUnitsState.selectedNeoUnits,
})
const mapDispatchToProps = dispatch => ({
  // onChangeView: viewType => dispatch({ type: 'CHANGE_VIEW', payload: viewType }),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(Toolbar);

// export default Toolbar;
