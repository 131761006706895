import {
    RECEIVE_DEVELOPERS,
    IS_LOADING,

    ADD_DEVELOPER,
    SUCCESS_ADD_DEVELOPER,
    FAIL_ADD_DEVELOPER,
    RESET_ADD_DEVELOPER_STATE,

    DELETE_DEVELOPER,
    SUCCESS_DELETE_DEVELOPER,
    FAIL_DELETE_DEVELOPER,

    RESET_DELETE_STATE
} from "../Constants/developers";

export default function (state = {}, action) {
    switch (action.type) {
        case RECEIVE_DEVELOPERS:
            return {
                ...state,
                developers: action.developers,
            }
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        case ADD_DEVELOPER:
            let developers = state.developers;
            if (developers) {
                developers.push(action.developer)
            } else {
                developers = [action.developer]
            }
            return {
                ...state,
                developers: [...developers]
            }
        case SUCCESS_ADD_DEVELOPER:
            return {
                ...state,
                added: action.success
            }
        case FAIL_ADD_DEVELOPER:
            return {
                ...state,
                added: action.error
            }
        case RESET_ADD_DEVELOPER_STATE:
            return {
                ...state,
                added: null
            }
        case DELETE_DEVELOPER:
            let newDevelopers;

            if (state.developers) {
                newDevelopers = state.developers.filter(developer => developer.id !== action.id)
            }
            return {
                ...state,
                developers: [...newDevelopers]
            }
        case SUCCESS_DELETE_DEVELOPER:
            return {
                ...state,
                deleted: { ...action.success }
            }
        case FAIL_DELETE_DEVELOPER:
            return {
                ...state,
                deleted: { ...action.error }
            }
        case RESET_DELETE_STATE:
            return {
                ...state,
                deleted: null
            }
        default:
            return state
    }
}