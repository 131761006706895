import XLSX from "xlsx";
import Swal from "sweetalert2";
const readInventoryFile = (selectedFile, obj) => {
  //file type
  const types = [
    "text/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];
  if (selectedFile) {
    if (selectedFile && !types.includes(selectedFile.type)) {
      Swal.fire({
        title: "Error!",
        text: "File Not Support",
        icon: "error",
        confirmButtonText: "change",
      });
      return;
    }
    const reader = new FileReader();
    var output = {};
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, {
        type: "binary",
      });
      let BreakException = {};
      try {
        workbook.SheetNames.forEach((sheetName, index) => {
          let XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          let json_object = JSON.stringify(XL_row_object);
          output = JSON.parse(json_object);
          obj.setState({
            fileData: output,
          });

          if (index === 0) {
            throw BreakException;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    };
    reader.onerror = function (event) {
      console.error("File could not be read! Code " + event.target.error.code);
    };
    reader.readAsBinaryString(selectedFile);
  }
};

export { readInventoryFile };
