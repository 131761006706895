import React, { Component } from 'react';
import styles from './index.module.scss';

import {
    FormGroup,
    FormControl,
    TextField,
    Divider,
    Grid,
    Button,
    CircularProgress,
    InputLabel,
    Select
} from '@material-ui/core';



import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigatePrevIcon from '@material-ui/icons/NavigateBefore';
import DoneIcone from '@material-ui/icons/Done';



import { connect } from "react-redux";
import { handleAddNewCompound } from "../../redux/Actions/compounds";
import { compose } from 'redux';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';

class AddNewCompoundForm extends Component {
    state = {
        is_submit: false,
        compound_name: "",
        page_icon: "",
        card_icon: "",
        brouchre: '',
        card_icon_Src: '',
        page_icon_Src: '',
        brouchre_Src: '',
        city: '',
        discrit: '',
        viewForm: 'data',
        disabledNex: false,
        disabledPrev: true,
        gallery: [],
        video: ''
    }
    setCompoundDataToState = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    catchIcons = (e) => {
        // console.log(e.target.files);

        if (e.target.files[0].type !== "application/pdf" || e.target.files[0].type !== "video/*") {
            const reader = new FileReader();
            let url;
            if (e.target.files[0]) {
                url = reader.readAsDataURL(e.target.files[0]);
            }
            const key = `${e.target.id}_Src`
            reader.onloadend = function (e) {
                this.setState({
                    [key]: [reader.result]
                })
            }.bind(this);
            console.log("add new Compound form url :", url);
        }
        this.setState({
            [e.target.id]: e.target.files[0],
        })

    }
    catchGallery = (e) => {
        console.log(e.target.files);
        this.setState({
            gallery: e.target.files
        })
    }
    addNewCompound = (e) => {
        e.preventDefault()
        this.setState({
            is_submit: !this.state.is_submit
        })


        let compoundDocuments = {
            card_icon: this.state.card_icon,
            page_icon: this.state.page_icon,
            photos: this.state.gallery,
            brochure: this.state.brouchre,
            video: this.state.video
        }
        this.props.firebase.uploadCompoundDocuments(
            "Milad",
            this.state.compound_name,
            compoundDocuments
        )
            .then(urls => {
                console.log("urls", urls)
                let newCompound = {
                    "developer_id": this.props.match.params.developer_id,
                    "developer_name": localStorage.getItem('developer_name'),
                    "compound_name": this.state.compound_name,
                    "city": this.state.city,
                    "district": this.state.discrit,
                    "page_icon_url": urls.page_icon_url,
                    "masterplan_map": urls.card_icon_url,
                    "brochure": urls.brochure_url,
                    "photos": urls.photosUrls,
                    "video": urls.video_url,
                }
                this.props.handleAddNewCompound(newCompound)
            })

    }
    handleChange = (event) => {
        const name = event.target.name;
        this.setState({
            [name]: event.target.value,
        });
    };

    nextView = () => {
        this.setState({
            viewForm: "gallery",
            disabledNex: true,
            disabledPrev: false,
        })
    }

    prevView = () => {
        this.setState({
            viewForm: "data",
            disabledPrev: true,
            disabledNex: false,
        })
    }

    render() {
        const {
            page_icon,
            card_icon,

            compound_name,
            viewForm,
            disabledNex,
            disabledPrev,
            gallery,
            brouchre,
        } = this.state

        let isValid = page_icon === ''
            || card_icon === ''
            || compound_name.trim() === ''
            || gallery.length === 0
            || brouchre === '';

        console.log(this.props)
        return (
            <main className={`${styles.compoundForm}`}>
                <h5> New Compound</h5>
                <Divider />
                <FormControl className={`${styles.photoArea}`} fullWidth={true}>
                    <Grid container justify="center" className={`${styles.fixAreaPos}`}>
                        <Grid item xs={3}>
                            <input accept="image/*" id="page_icon" type="file" onChange={this.catchIcons} />
                            <label htmlFor="page_icon">
                                <img src={
                                    this.state.page_icon_Src
                                        ? this.state.page_icon_Src
                                        : require("../../assets/camera-icon.svg")} alt="imag" width="50px" />
                            </label>

                        </Grid>
                        <Grid item xs={3}>
                            <input accept="image/*" id="card_icon" type="file" onChange={this.catchIcons} />
                            <label htmlFor="card_icon">
                                <img src={
                                    this.state.card_icon_Src
                                        ? this.state.card_icon_Src
                                        : require("../../assets/camera-icon.svg")} alt="imag" width="50px" />
                            </label>
                        </Grid>
                        <Grid item xs={3}>
                            <input accept="application/pdf" id="brouchre" type="file" onChange={this.catchIcons} />
                            <label htmlFor="brouchre">
                                <img src={require("../../assets/camera-icon.svg")} alt="imag" width="50px" />
                            </label>
                        </Grid>
                        <Grid item xs={3}>
                            <input accept="video/*" id="video" type="file" onChange={this.catchIcons} />
                            <label htmlFor="video">
                                <img src={require("../../assets/camera-icon.svg")} alt="imag" width="50px" />
                            </label>
                        </Grid>
                    </Grid>
                </FormControl>
                {
                    viewForm === "data" ?
                        (<div className={`${styles.dataArea}`}>
                            <FormGroup>
                                <FormControl fullWidth={true}>
                                    <TextField id="compound_name" label="Compound Name" onChange={this.setCompoundDataToState} />
                                </FormControl>
                            </FormGroup>
                            <Divider />

                            <Grid container>
                                <Grid item xs={12}>
                                    <InputLabel>Location</InputLabel>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl className={styles.formControl}>
                                        <InputLabel htmlFor="city">City</InputLabel>
                                        <Select
                                            native
                                            value={this.state.city}
                                            onChange={this.handleChange}
                                            inputProps={{
                                                name: 'city',
                                                id: 'city',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            <option value="City 1 ">City 1</option>
                                            <option value="City 2 ">City 2</option>
                                            <option value="City 3">City 3</option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl className={styles.formControl}>
                                        <InputLabel htmlFor="discrit">Discrit</InputLabel>
                                        <Select
                                            native
                                            value={this.state.discrit}
                                            onChange={this.handleChange}
                                            inputProps={{
                                                name: 'discrit',
                                                id: 'discrit',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            <option value="Discrit 1">Discrit 1</option>
                                            <option value="Discrit 2">Discrit 2</option>
                                            <option value="Discrit 3">Discrit 3</option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </div>)
                        : (
                            <div className={styles.gallery}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <FormGroup>
                                            <FormControl>
                                                <input accept="image/*" id="page_icon" type="file" multiple onChange={this.catchGallery} />
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {/* here view images */}
                                    </Grid>
                                </Grid>
                            </div>
                        )
                }
                <Grid container>
                    <Grid item xs={3}>
                        <FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={disabledPrev}
                                id="prevBtn"
                                onClick={this.prevView}>
                                <NavigatePrevIcon />
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={disabledNex}
                                id="nextBtn"
                                onClick={this.nextView}>
                                <NavigateNextIcon />
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isValid}
                                id="compound_submitBtn"
                                type="submit"
                                startIcon={
                                    (!this.state.is_submit) ?
                                        '' : <CircularProgress size={20} color="secondary" />
                                }
                                onClick={this.addNewCompound}>
                                <DoneIcone />
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </main>
        )
    }
}

const mapStateToProps = ({ compounds, developers }) => ({
    compounds: compounds,
    developers: developers
})
export default compose(
    withRouter,
    connect(mapStateToProps, { handleAddNewCompound: handleAddNewCompound }),
    withFirebase
)(AddNewCompoundForm)