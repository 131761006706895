import React, { Fragment } from "react";
import { withAuthorization } from "../../components/Session";
import { compose } from "redux";
import { withFirebase } from "../../components/Firebase";
import { withRouter } from "react-router-dom";

// import dateFormat from "dateformat";
import Typography from "@material-ui/core/Typography";
// import { makeStyles } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   toolbar: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-end",
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//   },
// }));

function Home() {
  // const classes = useStyles();
  return (
    <Fragment>
      <Typography paragraph>Home</Typography>
    </Fragment>
  );
}

const condition = (authUser) => !!authUser;

export default compose(
  withRouter,
  withFirebase,
  withAuthorization(condition)
)(Home);
