import React, { Component } from "react";
import SidebarFooter from "../../SidebarFooter";
import SignInForm from "../../SignInForm";
import SignUpForm from "../../SignUpForm";
import WelcomeBanner from "../../WelcomeBanner";

class LandingHeader extends Component {
  state = {
    signinORSignup: false,
  };
  changeView = () => {
    this.setState((prevState) => ({
      signinORSignup: !prevState.signinORSignup,
    }));
  };
  render() {
    return (
      <header className={`container-fluid`}>
        <section className={`row`}>
          <SidebarFooter />
          <section className="col-6">
            <div className="container-fluid">
              <div className="row">
                <WelcomeBanner />
                {this.state.signinORSignup ? (
                  <SignUpForm changeView={this.changeView} />
                ) : (
                  <SignInForm changeView={this.changeView} />
                )}
              </div>
            </div>
          </section>
        </section>
      </header>
    );
  }
}

export default LandingHeader;
