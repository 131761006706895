import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';

function InventoryAddFilter(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (e) => {
    props.addFilter(e.target.dataset.val);
    handleClose()
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button aria-controls="simple-menu" 
      aria-haspopup="true" 
      onClick={handleClick}
      color="primary"
      startIcon={<FilterListOutlinedIcon/>}>
        Add Filter
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem data-val="filterUnitDesign" onClick={(event) => handleSelect(event)}>Unit Design</MenuItem>
        <MenuItem data-val="filterPropertyType" onClick={(event) => handleSelect(event)}>Property Type</MenuItem>
        <MenuItem data-val="filterBuiltUpArea" onClick={(event) => handleSelect(event)}>Built Up Area</MenuItem>
      </Menu>
    </>
  );
}
export default InventoryAddFilter;
