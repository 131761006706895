import React, { Component } from "react";
import styles from './index.module.scss';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

class InventoryFinancingForm extends Component {

  submitHandler = (e) => {
    e.preventDefault()
  }
  changeViewHandler = () => {
    this.props.changeView()
  }
  render() {
    return (
      <div className={`${styles.missingFormArea}`}>
        <h6>Missing Units</h6>
        <Grid container justify="center">
          <Grid item xs={12}>
            <form onSubmit={this.submitHandler} noValidate autoComplete="off">
            <TextField id="down payment" label="down payment" />
            <TextField id="contract payment" label="contract payment" />
            <TextField id="cash discount" label="cash discount" />
            <TextField id="number of years" label="number of years" />
          </form>
          </Grid>
        </Grid>
        <div className={`${styles.nextBtn}`}>
          <Button 
            onClick={this.changeViewHandler}
            variant="contained" 
            color="primary">
            <NavigateNextIcon />
          </Button>
        </div>
        {/* <form
          onSubmit={this.submitHandler}
          >
          <label>down payment</label>
          <input></input>
          <label>contract payment</label>
          <input></input>
          <label>cash discount</label>
          <input></input>
          <label>number of years</label>
          <input></input>
          <button
            onClick={this.changeViewHandler}
          >
            NEXT
          </button>
        </form> */}
      </div>
    );
  }
}

export default InventoryFinancingForm;
