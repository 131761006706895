import React, { Component, Fragment } from "react";


import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import styled from "styled-components";
import "./index.css";

import AddNewDeveloperForm from "../AddNewDeveloperForm"
import AddNewCompoundForm from "../AddNewCompoundForm"
import AddNewPhaseForm from "../AddNewPhaseForm"

const ModalStyled = styled(Modal)`
  display: "flex";
  align-items: "center";
  justify-content: "center";
`;

class AddDeveloperDataModal extends Component {
    state = {
        open: false,
        isComplete: false,
    };

    handleOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const { open } = this.state;
        return (
            <Fragment>
                <Button
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={this.handleOpen}
                >
                    {
                        this.props.type === "developer"
                            ? "Add New Developer"
                            : this.props.type === "compound"
                                ? "Add New Compound"
                                : this.props.type === "phase"
                                    ? "ADD New Phase"
                                    : ""
                    }
                </Button>
                <ModalStyled
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className="paper">
                            {
                                this.props.type === "developer"
                                    ? <AddNewDeveloperForm />
                                    : this.props.type === "compound"
                                        ? <AddNewCompoundForm />
                                        : this.props.type === "phase"
                                            ? <AddNewPhaseForm />
                                            : null
                            }
                        </div>
                    </Fade>
                </ModalStyled>
            </Fragment>
        );
    }
}

export default (AddDeveloperDataModal)
