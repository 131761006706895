export const FETCH_COMPOUND_PHASES = "FETCH_COMPOUND_PHASES";
export const IS_LOADING = "IS_LOADING";
export const SUCCESS_FETCH_COMPOUND_PHASES = "SUCCESS_FETCH_COMPOUND_PHASES";
export const FAIL_FETCH_COMPOUND_PHASES = "FAIL_FETCH_COMPOUND_PHASES";

export const ADD_NEW_PHASE = "ADD_NEW_PHASE"
export const SUCCESS_ADD_NEW_PHASE = "SUCCESS_ADD_NEW_PHASE"
export const FAIL_ADD_NEW_PHASE = "FAIL_ADD_NEW_PHASE"
export const RESET_ADD_PHASE_STATE = "RESET_ADD_PHASE_STATE"

export const DELETE_PHASE = "DELETE_PHASE"
export const SUCCESS_DELETE_PHASE = "SUCCESS_DELETE_PHASE"
export const FAIL_DELETE_PHASE = "FAIL_DELETE_PHASE"
export const RESET_DELETE_STATE = "RESET_DELETE_STATE"