import React, { Component } from "react";
import MaterialTable, { MTableToolbar } from 'material-table';
import { connect } from 'react-redux'
import { compose } from 'redux'
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Toolbar from '../Toolbar';

class InventoryFinancialView extends Component {
  state = {
    filterUnitDesign: false,
    filterTotalPrice: false,
    filterDownPayment: false,
    filterContractPayment: false,
    filterYears: false,
    filterCashDiscount: false,
  }

  componentDidMount = () => {
    axios
      .get(`/units`)
      .then((res) => {
        let result = { ...res };
        console.log(result.data);
        let rawUnits = result.data.slice(0, 20);
        this.props.onSetNeoUnits(rawUnits);
      });
  }
  addFilter = (type) => {
    this.setState({
      filter: true,
      [type]: true
    })
  }
  selectBoxHandler = (rows, t) => {
    console.log(rows, t);
    let IDs = rows.map(row => row.tableData.id);
    this.props.onModifySelectedUnits(IDs)
  }
  render() {
    let options = {
      selection: true,
    }
    if (this.state.filter === true) {
      options = {
        selection: true,
        filtering: true
      }
    }

    let customizedUnits = this.props.neoUnits.map(rawUnit => {
      let unitDesign = rawUnit.data.listing.unit_design;
      console.log(unitDesign)
      let propertyType = rawUnit.data.listing.unit_type;
      let builtUpArea = rawUnit.data.listing.bua;
      let bathrooms = rawUnit.data.listing.bathrooms;
      let bedrooms = rawUnit.data.listing.bedrooms;
      let deliveryDate = rawUnit.data.listing.delivery_year;
      return {
        unitDesign,
        propertyType,
        builtUpArea,
        bathrooms,
        bedrooms,
        deliveryDate,
      }
    })
    console.log(this.props.neoUnits)
    console.log(customizedUnits)

    const dynamicColumns = [
      { title: 'unit design', field: 'unitDesign', filtering: this.state.filterUnitDesign },
      { title: 'total price', field: 'totalPrice', filtering: this.state.filterTotalPrice },
      { title: 'down paynemt', field: 'downPayment', filtering: this.state.filterDownPayment },
      { title: 'contract payment', field: 'contractPayment', type: 'numeric', filtering: this.state.filterContractPayment },
      { title: 'years', field: 'years', type: 'numeric', filtering: this.state.filterYears },
      { title: 'cash discount', field: 'cashDiscount', filtering: this.state.filterCashDiscount }
    ];

    const element = <h4>this changed</h4>;

    return (
      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            title={element}
            components={{
              Toolbar: props => (
                <>
                  {console.log(props)}
                  <MTableToolbar {...props} />
                  <Toolbar
                    changeViewType={this.props.changeViewType}
                    addFilter={this.addFilter}
                    removeFilter={this.props.removeFilter}
                  />
                </>
              ),
            }}
            columns={dynamicColumns}
            data={customizedUnits}
            options={options}
            onSelectionChange={(rows, t) => { this.selectBoxHandler(rows, t) }}
            editable={{
              // onRowAdd: newData =>
              //   new Promise((resolve, reject) => {
              //     setTimeout(() => {
              //       {
              //         const data = this.state.data;
              //         data.push(newData);
              //         this.setState({ data }, () => resolve());
              //       }
              //       resolve()
              //     }, 1000)
              //   }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      const data = this.state.data;
                      const index = data.indexOf(oldData);
                      data[index] = newData;
                      this.setState({ data }, () => resolve());
                    }
                    resolve()
                  }, 1000)
                }),
              // onRowDelete: oldData =>
              //   new Promise((resolve, reject) => {
              //     setTimeout(() => {
              //       {
              //         let data = this.state.data;
              //         const index = data.indexOf(oldData);
              //         data.splice(index, 1);
              //         this.setState({ data }, () => resolve());
              //       }
              //       resolve()
              //     }, 1000)
              //   }),
            }}
            detailPanel={rowData => {
              return (
                <div>collapsed area</div>
              )
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  neoUnits: state.neoUnitsState.neoUnits,
})
const mapDispatchToProps = dispatch => ({
  onModifySelectedUnits: IDs => dispatch({ type: 'MODIFY_NEO_UNITS', payload: IDs }),
  onSelectNeoUnit: id => dispatch({ type: 'SELECT_NEO_UNIT', payload: id }),
  onUnselectNeoUnit: id => dispatch({ type: 'UNSELECT_NEO_UNIT', payload: id }),
  onSetNeoUnits: units => dispatch({ type: 'SET_NEO_UNITS', payload: units }),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(InventoryFinancialView);
