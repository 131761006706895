import React, { Component } from "react";
import styles from './index.module.scss';

import Grid from '@material-ui/core/Grid';

import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

class InventoryCompletePopup extends Component {
  render() {
    return (
      <div className={`${styles.lastProcess}`}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <CheckCircleOutlineOutlinedIcon className="text-success" fontSize="large" />
            <h6 className="text-capitalize">all phases completed</h6>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default InventoryCompletePopup;
