import React from "react";
import { withFirebase } from "../Firebase";
import { Link } from "react-router-dom";

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const SignOutButton = ({ firebase }) => (
  <Link to="#" onClick={firebase.doSignOut}><ExitToAppIcon color="secondary" /></Link>
);
export default withFirebase(SignOutButton);
