import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { compose } from 'redux';
import { withAuthorization } from '../../components/Session';
import { withRouter, Link } from 'react-router-dom';
import { withFirebase } from '../../components/Firebase';

import * as ROUTES from "../../constants/routes"

import MainToolbar from "../../components/MainToolbar";

import Swal from "sweetalert2"

import withReactContent from 'sweetalert2-react-content'

import { handleFetchDeveloperCompounds, handleDeleteCompound } from "../../redux/Actions/compounds";

import MaterialTable, { MTableToolbar } from 'material-table'
import { connect } from 'react-redux';
import AddDeveloperDataModal from '../../components/AddDeveloperDataModal';


const MySwal = withReactContent(Swal)


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


class Compounds extends Component {

  state = {
    value: 0,
  }

  componentDidMount() {
    const developer_id = this.props.match.params.developer_id
    this.props.handleFetchDeveloperCompounds(developer_id)
  }
  changeTabs = (e, newValue) => {
    this.setState({
      value: newValue
    })
  }

  render() {


    const { value } = this.state

    let { compounds } = this.props


    let allCompounds = compounds && compounds.compounds

    let data = []

    if (allCompounds && allCompounds.length > 0) {
      data = allCompounds.map(compound => ({
        id: compound.id,
        compound_name: compound.compound_name,
        page_icon_url: compound.page_icon_url,
        location: `${compound.city} ${compound.district}`,
        brochure: compound.brochure
      }))
    }


    const dynamicColumns = [
      {
        title: "Compound", field: "page_icon_url",
        render: rowData => <Link to={`${ROUTES.COMPOUNDS}/${rowData.id}`} >
          <img src={rowData.page_icon_url} style={{ width: 50 }} alt={rowData.compound_name}
            onClick={() => {
              localStorage.setItem("compound_name", rowData.compound_name)
            }} />
        </Link>
      },
      {
        title: "Compound Name",
        field: "compound_name",
      },
      {
        title: "Location",
        field: "location",
      },
      {
        title: "Brochure",
        field: 'brochure',
        render: rowData => <a href={rowData.brochure} download target="_blank" rel="noopener noreferrer">Click to download</a>
      }
    ]

    if (compounds && compounds.added) {
      if (compounds.added.type) {
        // const compound = compounds.compounds[compounds.compounds.length - 1]
        MySwal.fire({
          icon: 'success',
          title: 'Success!',
          text: compounds.added.message,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: compounds.added.message,
          footer: '<a href>Why do I have this issue?</a>'
        })
      }
    }

    if (compounds && compounds.deleted) {

      if (compounds.deleted.type) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: compounds.deleted.message,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: compounds.deleted.message,
          footer: '<a href>Why do I have this issue?</a>'
        })
      }
    }

    return (
      <section className="compound">
        <div className="root">
          <AppBar position="static">
            <Tabs value={value} onChange={this.changeTabs} aria-label="simple tabs example">
              <Tab label="Compounds" {...a11yProps(0)} />
              <Tab label="Individual" {...a11yProps(1)} />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <MaterialTable
              isLoading={compounds && !compounds.isLoading}
              columns={dynamicColumns}
              data={data}
              components={{
                Toolbar: props => (
                  <>
                    <MTableToolbar {...props} />
                    <MainToolbar
                      tabs={[<AddDeveloperDataModal type="compound" />]}
                    />
                  </>
                )
              }}
              actions={[
                {
                  icon: 'delete',
                  tooltip: 'Save User',
                  onClick: (event, rowData) => {
                    Swal.fire({
                      title: 'Are you sure?',
                      text: "You won't be able to revert this!",
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                      if (result.value) {
                        this.props.handleDeleteCompound(rowData)
                      }
                    })
                  }
                }
              ]}
              options={{
                actionsColumnIndex: -1
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MaterialTable
              columns={[
                { title: 'Adı', field: 'name' },
                { title: 'Soyadı', field: 'surname' },
                { title: 'Doğum Yılı', field: 'birthYear', type: 'numeric' },
                { title: 'Doğum Yeri', field: 'birthCity', lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' } }
              ]}
              data={[{ name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 }]}
              title="Individual"
            />
          </TabPanel>
        </div>

      </section>
    );
  }
}


const condition = (authUser) => !!authUser;

const mapStateToProps = ({ compounds, developers }) => ({
  compounds: compounds,
  developers: developers
})

export default compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
  connect(mapStateToProps, {
    handleFetchDeveloperCompounds: handleFetchDeveloperCompounds,
    handleDeleteCompound: handleDeleteCompound
  })
)(Compounds);