export const FETCH_DEVELOPER_COMPOUNDS = "FETCH_DEVELOPER_COMPOUNDS";
export const IS_LOADING = "IS_LOADING";
export const SUCCESS_FETCH_DEVELOPER_COMPOUNDES = "SUCCESS_FETCH_DEVELOPER_COMPOUNDES";
export const FAIL_FETCH_DEVELOPER_COMPOUNDS = "FAIL_FETCH_DEVELOPER_COMPOUNDS";


export const ADD_COMPOUND = "ADD_COMPOUND"
export const SUCCESS_ADD_COMPOUND = "SUCCESS_ADD_COMPOUND"
export const FAIL_ADD_COMPOUND = "FAIL_ADD_COMPOUND"
export const RESET_ADD_COMPOUND_STATE = "RESET_ADD_COMPOUND_STATE"

export const DELETE_COMPOUND = "DELETE_COMPOUND";
export const SUCCESS_DELETE_COMPOUND = "SUCCESS_DELETE_COMPOUND";
export const FAIL_DELETE_COMPOUND = "FAIL_DELETE_COMPOUND";

export const RESET_DELETE_STATE = "RESET_DELETE_STATE"
