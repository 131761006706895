import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { withRouter, Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { compose } from "redux";

const INITIAL_STATE = {
  fullName: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null,
};

class SignUpForm extends Component {
  state = { ...INITIAL_STATE };

  onSubmit = (event) => {
    event.preventDefault();

    const { fullName, email, passwordOne } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        localStorage.removeItem("loggedUserId");
        localStorage.setItem("loggedUserId", authUser.user.uid);

        this.props.firebase.createNewUser(authUser.user.uid, fullName, email);
        this.props.history.push(ROUTES.HOME);
      })
      .catch((error) => {
        this.setState({ error });
      });
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { fullName, email, passwordOne, passwordTwo, error } = this.state;
    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      fullName === "";
    const { changeView } = this.props;

    return (
      <section className={`col-12`}>
        <form onSubmit={this.onSubmit} className={`py-5`}>
          <div className={`form-group`}>
            <input
              name="fullName"
              value={fullName}
              onChange={this.onChange}
              type="text"
              className={`form-control`}
              placeholder="Full Name"
            />
          </div>
          <div className={`form-group`}>
            <input
              name="email"
              value={email}
              onChange={this.onChange}
              placeholder="Email Address"
              type="email"
              className={`form-control`}
              id="exampleInputEmail1"
            />
          </div>
          <div className={`form-group`}>
            <input
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
              className={`form-control`}
            />
          </div>
          <div className={`form-group`}>
            <input
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm Password"
              className={`form-control`}
            />
          </div>
          <button
            className={`btn btn-outline-primary btn-block`}
            type="submit"
            disabled={isInvalid}
          >
            Sign Up
          </button>
          <Link to="#" onClick={changeView} className={`text-center`}>
            Already have account
          </Link>
          {error && <p>{error.message}</p>}
        </form>
      </section>
    );
  }
}

export default compose(withFirebase, withRouter)(SignUpForm);
