const setNeoUnits = (state, action) => ({
    ...state,
    neoUnits: action.payload
});

const getNeoUnits = (state, action) => ({
    ...state,
    neoUnits: action.payload
});

const modifyNeoUnits = (state, action) => ({
    ...state,
    selectedNeoUnits: action.payload
});

const selectNeoUnit = (state, action) => ({
    ...state,
    selectedNeoUnits: [...state.selectedNeoUnits, ...action.payload]
});
const unSelectNeoUnit = (state, action) => ({
    ...state,
    selectedNeoUnits: state.selectedNeoUnits.filter(x => x !== action.payload)
});

export { setNeoUnits, modifyNeoUnits, getNeoUnits, selectNeoUnit, unSelectNeoUnit }
