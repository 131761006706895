import { SET_NEO_UNITS, GET_NEO_UNITS, MODIFY_NEO_UNITS, SELECT_NEO_UNIT, UNSELECT_NEO_UNIT } from "../Constants/neoUnitsConstants";
import { setNeoUnits, getNeoUnits, modifyNeoUnits, selectNeoUnit, unSelectNeoUnit } from "../Actions/neoUnitsActions";

const INITIAL_STATE = {
    neoUnits: [],
    selectedNeoUnits: []
}

const neoUnitsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_NEO_UNITS: {
            return setNeoUnits(state, action);
        }
        case GET_NEO_UNITS: {
            return getNeoUnits(state, action);
        }
        case MODIFY_NEO_UNITS: {
            return modifyNeoUnits(state, action);
        }
        case SELECT_NEO_UNIT: {
            return selectNeoUnit(state, action);
        }
        case UNSELECT_NEO_UNIT: {
            return unSelectNeoUnit(state, action);
        }
        default:
            return state;
    }
};

export default neoUnitsReducer;
