import React, { Component } from "react";
import MaterialTable, { MTableToolbar } from 'material-table';

import Toolbar from '../Toolbar';


const dynamicColumns =[
    { title: 'XXXXXXXXXXXX', field: 'name' },
    { title: 'Surname', field: 'imageUrl', render: rowData => <input
      onClick={()=>rowData.bla()} 
      style={{width: 40, borderRadius: '50%'}}/> },
    { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
    {title: 'Birth Place',field: 'birthCity', lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
    },
];

const dynamicData = [
  { name: 'Mehmet', imageUrl: 'https://avatars0.githubusercontent.com/u/7895451?s=460&v=4', 
  birthYear: 1987, birthCity: 63, bla: () => alert(33333) },
  { name: 'Zerya Betül', imageUrl: 'https://avatars0.githubusercontent.com/u/7895451?s=460&v=4'
  , birthYear: 2017, birthCity: 34 },
];

class InventoryConstructionView extends Component {
  render() {
    return (
      <MaterialTable
      // other props
      components={{
        Toolbar: props => (
            <>
            {console.log("props", props)}
              <MTableToolbar {...props}/>
              <Toolbar changeViewType={this.props.changeViewType} />
            </>
          ),
        }}
        columns={dynamicColumns}
        data={dynamicData}
        options={{
          selection: true
        }}
      />
    );
  }
}

export default InventoryConstructionView; 
