import React, { Component } from "react";
import styles from './index.module.scss';

import Grid from '@material-ui/core/Grid';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

class InventoryIncompletePopup extends Component {
  render() {
    return (
      <div className={`${styles.lastProcess}`}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <ErrorOutlineOutlinedIcon className="text-warning" fontSize="large" />
            <h6 className="text-capitalize">some phases need to complete</h6>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default InventoryIncompletePopup;
