import { CHANGE_VIEW } from "../Constants/viewsConstants";
import { changeView } from "../Actions/viewsActions";

const INITIAL_STATE = {
    viewType : 'basic'
}

const ViewsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_VIEW: {
            return changeView(state, action);
        }
        default:
            return state;
    }
};

export default ViewsReducer;
