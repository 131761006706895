import axios from "axios";
import {
    RECEIVE_DEVELOPERS,
    IS_LOADING,
    
    ADD_DEVELOPER,
    SUCCESS_ADD_DEVELOPER,
    FAIL_ADD_DEVELOPER,
    RESET_ADD_DEVELOPER_STATE,

    DELETE_DEVELOPER,
    SUCCESS_DELETE_DEVELOPER,
    FAIL_DELETE_DEVELOPER,
    RESET_DELETE_STATE
} from "../Constants/developers";

const receivedDevelopers = (developers) => ({
    type: RECEIVE_DEVELOPERS,
    developers,
})

const addDeveloper = (developer) => ({
    type: ADD_DEVELOPER,
    developer,
})

const successAddDeveloper = (success) => ({
    type: SUCCESS_ADD_DEVELOPER,
    success,
})

const failedAddDeveloper = (error) => ({
    type: FAIL_ADD_DEVELOPER,
    error,
})

const deleteDeveloper = (id) => ({
    type: DELETE_DEVELOPER,
    id,
})

const successDeleteDeveloper = (success) => ({
    type: SUCCESS_DELETE_DEVELOPER,
    success,
})

const failedDeleteDeveloper = (error) => ({
    type: FAIL_DELETE_DEVELOPER,
    error,
})

const resetDeleteState = (deleted) => ({
    type: RESET_DELETE_STATE,
    deleted
})

const resetAddState = (added) => ({
    type: RESET_ADD_DEVELOPER_STATE,
    added
})

const loading = (isLoading) => ({
    type: IS_LOADING,
    isLoading
})

export const handleAddNewDeveloper = (newDeveloper) => (dispatch) => {

    axios.post('/developers', { ...newDeveloper })
        .then(res => {
            console.log(res)
            dispatch(successAddDeveloper({
                type: true,
                message: "Successfully Added new Developer"
            }))
            let developerData = {
                id: res.data.id,
                ...res.data.data
            }
            dispatch(addDeveloper(developerData))
            dispatch(resetAddState(null))
        }).catch(error => {
            console.log(error);
            dispatch(failedAddDeveloper({
                type: false,
                message: "Failed to add new developer"
            }))
            dispatch(resetAddState(null))
        })

}


export const fetchDeveloperData = () => async (dispatch) => {
    dispatch(loading(false))
    axios.get('/developers').then(res => {
        let developers = res.data.allDevelopers.map(developer => ({
            id: developer.id,
            ...developer.data
        }))
        dispatch(receivedDevelopers(developers))
        dispatch(loading(true))
    }).catch(error => {
        console.log(error);
        dispatch(loading(true))
    })


}


export const handleDeleteDevelopers = (developer) => async (dispatch) => {

    dispatch(deleteDeveloper(developer.id))

    axios.delete(`/developers/historical/${developer.id}`)
        .then(res => {
            console.log(res.data);
            dispatch(successDeleteDeveloper({
                type: true,
                message: `Successfully delete developer: ${developer.developer_name}`
            }))
            dispatch(resetDeleteState(null))
        })
        .catch(error => {
            console.log(error);
            dispatch(failedDeleteDeveloper({
                type: false,
                message: `Error to Delete this developer: ${developer.developer_name}`
            }))
            dispatch(addDeveloper(developer))
            dispatch(resetDeleteState(null))
        })

}
