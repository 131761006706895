import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import Navigation from "../../components/Navigation";
import LandingPage from "../Landing";
import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../../components/Session";

import AuthContainer from "../AuthContainer";

const App = () => (
  <Router>
    <Switch>      
    <Route exact path={ROUTES.LANDING} component={LandingPage} />
    <Route path="/auth" component={AuthContainer} />
    </Switch>
  </Router>
);

export default withAuthentication(App);
