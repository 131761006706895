import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from 'redux'

// import './index.css';
import styles from './index.module.scss'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

class InventorySheetUpload extends Component {

  state = {
    compound_name: "",
    phase_name: "",
    is_compound: false,
    is_upload: false,
  };

  onchange = (e) => {
    const selectedFile = e.target.files[0];
    this.props.readingFileData(selectedFile);
    this.setState({
      is_upload: !this.state.is_upload
    })
  };

  //if inventory is compund or not
  isChecked = (e) => {
    this.setState((prevState) => ({
      is_compound: !prevState.is_compound,
    }));
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };


  render() {
    const { is_compound } = this.state;
    // console.log("From Redux: ", this.props);

    return (
      <section className={`${styles.uploadArea}`}>
        <FormControlLabel
          control={<Checkbox onChange={this.isChecked} name="iscompound" />}
          label="is compound"
        />
        {is_compound ? (
          <section>
            <TextField id="Compund Name"
             label="Compund Name" 
             color="primary"
             name="compound_name"
             onChange={this.handleChange}
            />
            <TextField id="Phase Name"
              label="Phase Name" 
              color="primary"
              name="phase_name"
              onChange={this.handleChange}
            />
            <hr />
          </section>
        ) : null}
        <div className={`${styles.uploadAssets}`}>
          {(!this.state.is_upload) ?
            <>
              <div className={`${styles.dragArea}`}>
                <CloudUploadIcon className={`${styles.icon}`} />
                <h6>drag drop to upload file</h6>
              </div> 
              <input
                accept=".xls, .xlsx, .csv"
                style={{display: 'none'}}
                id="contained-button-file"
                multiple
                type="file"
                onChange={this.onchange}
              />
              <label htmlFor="contained-button-file">
                <Button className="btn-primary" variant="contained" color="primary" component="span">
                  select file
                </Button>
              </label>
            </> :
            <div className={`${styles.fileCompletedArea}`}>
              <CircularProgress variant="static" value={100} size={100} thickness={4.6} />
              <h5>100%</h5>
              <h6>file upladed</h6>
            </div>}
        </div>
        {/* <input
          type="file"
          onChange={this.onchange}
          accept=".xls, .xlsx, .csv"
        /> */}
      </section>
    );
  }
}
const mapStateToProps = ({ units }) => ({
  units: units,
});

const mapDispatchToProps = dispatch => ({
  onSetUnits: units => dispatch({ type: 'UNITS_SET', units }),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(InventorySheetUpload)