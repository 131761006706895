import {
    FETCH_DEVELOPER_COMPOUNDS,
    FAIL_FETCH_DEVELOPER_COMPOUNDS,
    IS_LOADING,
    SUCCESS_FETCH_DEVELOPER_COMPOUNDES,

    ADD_COMPOUND,
    SUCCESS_ADD_COMPOUND,
    FAIL_ADD_COMPOUND,
    RESET_ADD_COMPOUND_STATE,

    DELETE_COMPOUND,
    SUCCESS_DELETE_COMPOUND,
    FAIL_DELETE_COMPOUND,
    RESET_DELETE_STATE,
} from "../Constants/compounds";


export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_DEVELOPER_COMPOUNDS:
            return {
                ...state,
                compounds: action.compounds
            }
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        case SUCCESS_FETCH_DEVELOPER_COMPOUNDES:
            return {
                ...state,
                compoundsFetched: state.compoundsFetched
            }
        case FAIL_FETCH_DEVELOPER_COMPOUNDS:
            return {
                ...state,
                compoundsFetched: state.compoundsFetched
            }
        case ADD_COMPOUND:
            let compounds = state.compounds;
            if (compounds) {
                compounds.push(action.compound)
            } else {
                compounds = [action.compound]
            }
            return {
                ...state,
                compounds: [...compounds]
            }
        case SUCCESS_ADD_COMPOUND:
            return {
                ...state,
                added: action.success
            }
        case FAIL_ADD_COMPOUND:
            return {
                ...state,
                added: action.error
            }
        case RESET_ADD_COMPOUND_STATE:
            return {
                ...state,
                added: null
            }
        case DELETE_COMPOUND:
            let newCompounds;

            if (state.compounds) {
                newCompounds = state.compounds.filter(compound => compound.id !== action.id)
            }
            return {
                ...state,
                compounds: [...newCompounds]
            }
        case SUCCESS_DELETE_COMPOUND:
            return {
                ...state,
                deleted: { ...action.success }
            }
        case FAIL_DELETE_COMPOUND:
            return {
                ...state,
                deleted: { ...action.error }
            }
        case RESET_DELETE_STATE:
            return {
                ...state,
                deleted: null
            }
        default:
            return state
    }
}