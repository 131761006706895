import React from "react";
import Sidebar from "../../components/Sidebar";
import { Route, Switch } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import Home from "../Home";
import Dashboard from "../Dashboard";
import PasswordForgetPage from "../../components/PasswordForget";
import Inventory from "../Inventory";
import Inbox from "../Inbox";
import Requests from "../Requests";
import SettingsContainer from "../SettingsContainer";
import NotFound from "../../components/NotFound";
import Developers from "../Developers";
import Compounds from "../Compounds";
import Phases from "../Phases";
// import Toolbar from "../../components/Toolbar";
import { makeStyles } from "@material-ui/core";

import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
  fixBreadcrumbsPos: {
    margin: theme.spacing(12, 3),
    marginBottom: theme.spacing(3),
  }
}));
const AuthContainer = ({ match }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Sidebar />
      <Grid container>
          <Grid item xs={12}>
            <Breadcrumbs className={classes.fixBreadcrumbsPos} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Link color="inherit" href="/">
                Material-UI
              </Link>
              <Link color="inherit" href="/getting-started/installation/">
                Core
              </Link>
              <Typography color="textPrimary">Breadcrumb</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <main className={classes.content}>
              <Switch>
                <Route exact path={`/auth/`} component={Home} />
                <Route path={`${ROUTES.HOME}`} component={Home} />
                <Route path={`${ROUTES.DASHBOARD}`} component={Dashboard} />
                <Route path={`${ROUTES.INVENTORY}`} component={Inventory} />
                <Route path={`${ROUTES.INBOX}`} component={Inbox} />
                <Route path={`${ROUTES.REQUESTS}`} component={Requests} />
                <Route
                  path={`${ROUTES.PASSWORD_FORGET}`}
                  component={PasswordForgetPage}
                />
                <Route path={`${ROUTES.SETTINGS}`} component={SettingsContainer} />
                <Route exact path={`${ROUTES.DEVELOPERS}`} component={Developers} />
                <Route exact path={`${ROUTES.DEVELOPERS}/:developer_id`} component={Compounds} />
                <Route exact path={`${ROUTES.COMPOUNDS}/:compound_id`} component={Phases} />
                <Route path="**" component={NotFound} />
              </Switch>
            </main>
          </Grid>
      </Grid>
    </div>
  );
};

export default AuthContainer;
