import React, { Component } from "react";

import { AuthUserContext, withAuthorization } from "../../components/Session";
import { PasswordForgetForm } from "../../components/PasswordForget";
import PasswordChangeForm from "../../components/PasswordChange";

class SettingsContainer extends Component {
  render() {
    return (
      <div>
        SettingsContainer
        <AuthUserContext.Consumer>
          {(authUser) => (
            <div>
              <h1>Account: {authUser.email}</h1>
              <PasswordForgetForm />
              <PasswordChangeForm />
            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(SettingsContainer);
