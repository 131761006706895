import React, { Fragment } from "react";
import { withAuthorization } from "../../components/Session";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import { withRouter } from "react-router-dom";
// import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

// const useStyles = makeStyles((theme) => ({
//   toolbar: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-end",
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//   },
// }));

function Dashboard() {
  // const classes = useStyles();
  return (
    <Fragment>
      <Typography paragraph>Dashboard</Typography>
    </Fragment>
  );
}
const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(Dashboard);
