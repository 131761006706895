import Joi from "@hapi/joi";

// "developer",
// "compound",
// "phase",
const unitSchema = Joi.object({
  developer: Joi.string(),
  compound: Joi.string(),
  phase: Joi.string(),
  unit_design: Joi.string(),
  unit_id: Joi.string(),
  total_price: Joi.number(),
  unit_type: Joi.string(),
  delivery_month: Joi.number(),
  delivery_year: Joi.number(),
  ready_to_move: Joi.boolean(),
  bua: Joi.number(),
  bedrooms: Joi.number(),
  bathrooms: Joi.number(),
  finishing_type: Joi.string(),
  land: Joi.number(),
  garden: Joi.boolean(),
  garage: Joi.boolean(),
});

export { unitSchema };
