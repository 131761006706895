import {
    FETCH_COMPOUND_PHASES,
    SUCCESS_FETCH_COMPOUND_PHASES,
    FAIL_FETCH_COMPOUND_PHASES,
    IS_LOADING,

    ADD_NEW_PHASE,
    SUCCESS_ADD_NEW_PHASE,
    FAIL_ADD_NEW_PHASE,
    RESET_ADD_PHASE_STATE,

    DELETE_PHASE,
    SUCCESS_DELETE_PHASE,
    FAIL_DELETE_PHASE,
    RESET_DELETE_STATE,

} from "../Constants/phases"


export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_COMPOUND_PHASES:
            return {
                ...state,
                phases: action.phases
            }
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        case SUCCESS_FETCH_COMPOUND_PHASES:
            return {
                ...state,
                fetched: state.success
            }
        case FAIL_FETCH_COMPOUND_PHASES:
            return {
                ...state,
                fetched: state.error
            }
        case ADD_NEW_PHASE:
            let phases = state.phases;
            if (phases) {
                phases.push(action.phase)
            } else {
                phases = [action.phase]
            }
            return {
                ...state,
                phases: [...phases]
            }
        case SUCCESS_ADD_NEW_PHASE:
            return {
                ...state,
                added: action.success
            }
        case FAIL_ADD_NEW_PHASE:
            return {
                ...state,
                added: action.error
            }
        case RESET_ADD_PHASE_STATE:
            return {
                ...state,
                added: null
            }
        case DELETE_PHASE:
            let newPhases;

            if (state.phases) {
                newPhases = state.phases.filter(phase => phase.id !== action.id)
            }
            return {
                ...state,
                phases: [...newPhases]
            }
        case SUCCESS_DELETE_PHASE:
            return {
                ...state,
                deleted: { ...action.success }
            }
        case FAIL_DELETE_PHASE:
            return {
                ...state,
                deleted: { ...action.error }
            }
        case RESET_DELETE_STATE:
            return {
                ...state,
                deleted: null
            }
        default:
            return state
    }
}