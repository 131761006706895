import React, { Component, Fragment } from "react";

import { LandingHeader, WhyContainer, HowContainer } from "../../components/LandingPageParts";
import Footer from "../../components/Footer";
import isLoggin from "../../components/Session/isLoggin";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

class LandingPage extends Component {
  render() {
    return (
      <Fragment>
        <LandingHeader />
        <WhyContainer />
        <HowContainer />        
        <Footer />
      </Fragment>
    );
  }
}

const condition = (authUser) => !!authUser;
export default compose(
  withRouter,
  isLoggin(condition)
)(LandingPage);
